import { DemoSection } from "np-platform-client/component/demo";
import { ModerationQueueCommentCard } from "../../../component/zdf/moderationqueue/moderationqueuecommentcard";
import { Datastore } from "np-platform-client/util/datastore";
import { useEffect, useState } from "react";
import { Banner } from "np-platform-client/component/banner";
import { UtilityText } from "np-platform-client/component/text";
import { View } from "react-native";
import { HorizBox, Pad } from "np-platform-client/component/basics";
import { defaultPersonaList, personaListToMap } from "np-platform-client/util/testpersonas";
import { JudgementCard } from "../../../component/zdf/judgementcard";
import { DemoHeader } from "np-platform-client/system/demo";
import { ModerationCommentListItem } from "../../../component/zdf/modcommentoverview";

const comments = [
    {
        key: "c0",
        from: "a",
        time: Date.now() - 10000000,
        instanceKey: "q0",
        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        violates: true,
    },
    {
        key: "c1",
        from: "b",
        time: Date.now() - 10000000,
        instanceKey: "q0",
        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
    },
    {
        key: "c2",
        from: "c",
        time: Date.now() - 10000000,
        instanceKey: "q0",
        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        violates: true,
    },
    {
        key: "c3",
        from: "d",
        time: Date.now() - 10000000,
        instanceKey: "q0",
        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        violates: true,
    }
]

const modAuthors = [
    {
        ...defaultPersonaList[0],
        authorMemberSince: 0,
        authorPhoto: defaultPersonaList[0].photoUrl,
        authorName: defaultPersonaList[0].name,
    },
    {
        ...defaultPersonaList[1],
        authorMemberSince: 0,
        authorPhoto: defaultPersonaList[1].photoUrl,
        authorName: defaultPersonaList[1].name,
    },
    {
        ...defaultPersonaList[2],
        authorMemberSince: 0,
        authorPhoto: defaultPersonaList[2].photoUrl,
        authorName: defaultPersonaList[2].name,
    },
    {
        ...defaultPersonaList[3],
        authorMemberSince: 0,
        authorPhoto: defaultPersonaList[3].photoUrl,
        authorName: defaultPersonaList[3].name,
        reporterPhoto: defaultPersonaList[4].photoUrl,
        reporterName: defaultPersonaList[4].name,
    }
]

const modTasks = [
    {
        key: "c0",
        time: Date.now() - 10000000,
        instanceKey: "q0",
        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        violations:
            '["**Avoid uncivil or disrespectful language**. You can express any opinion, however you must do so in a respectful way.","**Avoid using an angry or hostile tone.**"]',
        source: "premoderation",
    },
    {
        key: "c1",
        time: Date.now() - 10000000,
        instanceKey: "q0",
        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    },
    {
        key: "c2",
        time: Date.now() - 10000000,
        instanceKey: "q0",
        text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        violations: '["No spam or commercial content"]',
        appealText:
            "Lorem ipsum is not spam, it's a way of life. Since the dawn of humanity people used it to further spread their belives in an efficent and coherent way. Only true belivers will understand the holy scripture, after studying it with dedication!",
        source: "premoderation",
    },
    {
        key: "c3",
        time: Date.now() - 10000000,
        instanceKey: "q0",
        text: "Find amazing cars at amazing-card dot com!",
        violations: '["No spam or commercial content"]',
        source: "report",
    },
];

const question = {
    "q0": {
        from: "a",
        key: "q0",
        question: "Godzilla vs King Kong: Who will win?"
    }
}
const messageCount = {
    moderatorNotes: {
        "c0": {
            amount: 2
        }
    }
}
const moderatorNotes = {
    "n0": {
        from: modAuthors[0].key,
        key: "n0",
        modTaskKey: "c0",
        time: Date.now() - 1000,
        text: "This could be spam"
    },
    "n1": {
        from: modAuthors[1].key,
        key: "n1",
        modTaskKey: "c0",
        time: Date.now() - 500,
        text: "Yeah maybe, seems likely"

    }
}

const taskWatchStates = {
    "c0": {
        modID: "a",
        startTime: Date.now()
    },
    "c1": {
        modID: "b",
        startTime: Date.now(),
        name:"Thomas Edison"
    },
}
const modulePublicModeration = {
    taskWatchState: taskWatchStates
}
export function ModerationCommentCardDemo() {

    const [selectedCard, setSelectedCard] = useState(null)
    const [notes, setNotes] = useState(moderatorNotes)    
    const onPressView = () => {
        alert("View pressed. Should navigate to thread")
    }

    const onClickCard = (item) => {
        setSelectedCard(item.key)
    }

    const onExpandCard = (item) => {
        setSelectedCard(item.key)
    }

    const onCollapseCard = (item) => {
        setSelectedCard(null)
    }
    async function getModeratorNotesApi({ serverstore, modTaskKey }) {
        return notes
    }
    async function setModeratorNoteApi({ datastore, from, text, modTaskKey }) {
        const amountComments = messageCount.moderatorNotes.c0.amount
        messageCount.moderatorNotes.c0.amount = amountComments + 1
        const key = Date.now() - 1000
        const newData = {}
        newData[key]={"from":from,"text":text,"modTaskKey":modTaskKey,"time":Date.now()}
        setNotes({...notes,...newData})

    }
    const serverCalls = {
        moderationZdf: {
            getModeratorNotes: getModeratorNotesApi,
            setModeratorNote: setModeratorNoteApi,
            watchTask: () => { },
            unwatchTask: () => { },
            getModItemAuthorsByIds: ({ authorIds }) => {
                const authors = modAuthors.reduce((acc, item) => { acc[item.key] = item; return acc; }, {});
                return authors
            },

        }
    }
    return (
        <>
            <Datastore personaPreview={personaListToMap(defaultPersonaList)} collections={{ comment: comments }} modulePublic={{ question: question }} serverCall={serverCalls}>

                <DemoSection label="Comment card without rule violations">
                    <HorizBox>
                        <View style={{ width: "70%" }}>
                            <ModerationQueueCommentCard
                                modAuthor={modAuthors[1]}
                                modTask={modTasks[1]}
                                highlighted={selectedCard === comments[1].key}
                                comment={comments[1]}
                                onPressView={onPressView}
                                onClickCard={onClickCard}
                                onExpand={onExpandCard}
                                onCollapse={onCollapseCard}
                            />
                        </View>
                        {selectedCard === comments[1].key && (
                            <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                                <Pad size={16} />
                                <View style={{ display: "contents" }}>
                                    <JudgementCard modTask={modTasks[1]} />
                                </View>
                            </View>
                        )}
                    </HorizBox>
                </DemoSection>

                <DemoSection label="Comment card with AI flag">
                    <HorizBox>
                        <View style={{ width: "70%" }}>
                            <ModerationQueueCommentCard
                                modAuthor={modAuthors[0]}
                                modTask={modTasks[0]}
                                highlighted={selectedCard === comments[0].key}
                                comment={comments[0]}
                                onPressView={onPressView}
                                onClickCard={onClickCard}
                                onExpand={onExpandCard}
                                onCollapse={onCollapseCard}
                            />
                        </View>
                        {selectedCard === comments[0].key && (
                            <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                                <Pad size={16} />
                                <View style={{ display: "contents" }}>
                                    <JudgementCard modTask={modTasks[0]} />
                                </View>
                            </View>
                        )}
                    </HorizBox>
                </DemoSection>

                <DemoSection label="Comment card with AI flag & appeal text">
                    <HorizBox>
                        <View style={{ width: "70%" }}>
                            <ModerationQueueCommentCard
                                modAuthor={modAuthors[2]}
                                modTask={modTasks[2]}
                                highlighted={selectedCard === comments[2].key}
                                comment={comments[2]}
                                onPressView={onPressView}
                                onClickCard={onClickCard}
                                onExpand={onExpandCard}
                                onCollapse={onCollapseCard}
                            />
                        </View>
                        {selectedCard === comments[2].key && (
                            <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                                <Pad size={16} />
                                <View style={{ display: "contents" }}>
                                    <JudgementCard modTask={modTasks[2]} />
                                </View>
                            </View>
                        )}
                    </HorizBox>
                </DemoSection>

                <DemoSection label="Comment card with user report">
                    <HorizBox>
                        <View style={{ width: "70%" }}>
                            <ModerationQueueCommentCard
                                modAuthor={modAuthors[3]}
                                modTask={modTasks[3]}
                                highlighted={selectedCard === comments[3].key}
                                comment={comments[3]}
                                onPressView={onPressView}
                                onClickCard={onClickCard}
                                onExpand={onExpandCard}
                                onCollapse={onCollapseCard}
                            />
                        </View>
                        {selectedCard === comments[3].key && (
                            <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                                <Pad size={16} />
                                <View style={{ display: "contents" }}>
                                    <JudgementCard modTask={modTasks[3]} />
                                </View>
                            </View>
                        )}
                    </HorizBox>
                </DemoSection>
            </Datastore>
            <Datastore isAdmin={true} collections={{ comment: comments }} modulePublic={{ question: question, moderation: messageCount }} roles={['Moderator']} serverCall={serverCalls}>
                <DemoSection label="Card with moderator notes">
                    <HorizBox>
                        <View style={{ width: "70%" }}>
                            <ModerationQueueCommentCard
                                modAuthor={modAuthors[0]}
                                modTask={modTasks[0]}
                                highlighted={selectedCard === comments[0].key}
                                comment={comments[0]}
                                onPressView={onPressView}
                                onClickCard={onClickCard}
                                onExpand={onExpandCard}
                                onCollapse={onCollapseCard}
                            />
                        </View>
                        {selectedCard === comments[0].key && (
                            <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                                <Pad size={16} />
                                <View style={{ display: "contents" }}>
                                    <JudgementCard modTask={modTasks[0]} />
                                </View>
                            </View>
                        )}
                    </HorizBox>
                </DemoSection>
            </Datastore>
            <Datastore personaPreview={personaListToMap(defaultPersonaList)} collections={{ comment: comments }} modulePublic={{ question: question, moderation: modulePublicModeration }}>
                <DemoSection label="Cards with watching states">
                    <ModerationCommentListItem comment={comments[0]} modTask={modTasks[0]} modAuthor={modAuthors[0]} />
                    <ModerationCommentListItem comment={comments[1]} modTask={modTasks[1]} modAuthor={modAuthors[1]} />
                </DemoSection>
            </Datastore>
        </>
    );
}