import { Add, ArrowUpRight, Chat, Subtract, Time, ViewFilled } from "@carbon/icons-react";
import { Banner } from "np-platform-client/component/banner";
import { HorizBox, HoverView, Pad, PadBox } from "np-platform-client/component/basics";
import { CTAButton, IconButton, SubtleButton, Tag, TextButton } from "np-platform-client/component/button";
import { colorBlack, colorBlueBackground, colorGreyBorder, colorLightGreen, colorPink, colorPurpleBackground, colorTextBlue, colorTextGrey, colorWhite } from "np-platform-client/component/color";
import { SpacedArray } from "np-platform-client/component/demo";
import { Byline, FaceImage } from "np-platform-client/component/people";
import { Heading, Paragraph, TextField, TextFieldButton, UtilityText } from "np-platform-client/component/text";
import { useDatastore, useModulePublicData, usePersonaKey } from "np-platform-client/util/datastore";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { formatDate } from "np-platform-client/component/date";
import { RichText } from "np-platform-client/component/richtext";
import { ModerationFilters } from "../modcommentoverview";
import { updateModerationSessionData } from "../../../structure/zdf/moddashboard";
import { useParentDatastore } from "../parentDatastore";
import { useModerationAuthorById, useModerationAuthorsByIds } from "../../moderation";

export function ModerationQueueCommentCard({
    comment,
    modAuthor,
    modTask,
    onPressView,
    viewLabel = "View",
    onClickCard,
    onExpand,
    onCollapse,
    highlighted,
    taskWatchState,
}) {

    const expanded = highlighted;
    const datastore = useDatastore()
    const linkedQuestion = useModulePublicData("question", [modTask.instanceKey]);
    const personaKey = usePersonaKey()
    const amIviewing = taskWatchState?.modID === personaKey
    let name = "";
    if (taskWatchState) {
        name = taskWatchState.name
    }

    const onPressWholeCard = () => {
        onClickCard && onPressExpand(comment)
    }

    const onPressExpand = () => {
        onExpand && onExpand(comment)
    }

    const onPressCollapse = () => {
        onCollapse && onCollapse(comment)
    }

    const expandedSection = (
        <>
            <MessageToModerator message={modTask.appealText} commentTime={comment.time} />
            <CommentInformation comment={comment} modTask={modTask} />
            <NotesFromColleagues modTask={modTask} />

            {/* TODO: Does "premoderation" only refer to AI pre-moderation or would other features such as the blacklist also list "premoderation" as the source? */}
            {modTask.source === "premoderation" && <AIFlag modTask={modTask} />}
            {modTask.source === "report" && <ViolationInformation modTask={modTask} modAuthor={modAuthor} />}
            {modTask.judgement === "reject" && <ViolationInformation modTask={modTask} modAuthor={modAuthor} type="rejection" />}

            {/* We need to use comment.from as the user ID here instead of modAuthor.from because there is only one "from" per mod task. If a user reports a comment, modAuthor.from refers to the reporter and the comment author's ID is lost. */}
            <UserInformation modAuthor={modAuthor} userId={comment.from} />
            <SubtleButton icon={Subtract} label={"Show less"} onPress={onPressCollapse} />
        </>
    );

    return (
        <HoverView
            style={[
                ModQueueCommentCardStyle.cardContainer,
                { borderWidth: 1, borderColor: expanded ? colorBlack : colorWhite, backgroundColor: colorWhite },
            ]}
            onPress={!expanded && !highlighted && onPressWholeCard}
        >
            <PadBox horiz={20} vert={20}>
                <View style={{ gap: 16 }}>
                    <View style={[ModQueueCommentCardStyle.visibilityBar]}>
                        <View style={ModQueueCommentCardStyle.eyeIcon}>
                            <ViewFilled color={colorTextBlue}/>
                            {(taskWatchState && !(!highlighted && amIviewing)) && <View>
                                {amIviewing && <UtilityText color={colorTextBlue} type="tiny" label={"You are viewing"} /> }
                                {!amIviewing &&
                                    <HorizBox>
                                        <UtilityText color={colorTextBlue} type="tiny" label={name} />
                                        <UtilityText color={colorTextBlue} type="tiny" label={" is viewing"} />
                                    </HorizBox>
                                }      
                            </View>}
                        </View>
                    </View>
                    {modAuthor && (
                        <BylineModerationCard
                            photoUrl={modAuthor.authorPhoto}
                            name={modAuthor.authorName}
                            userId={comment.from}
                            size="tiny"
                            SecondaryLineComponent={() =>
                                FormattedDateTime({ timestamp: comment.time, color: colorTextGrey })
                            }
                        />
                    )}
                    <Paragraph text={comment.text} numberOfLines={!expanded && 2} />
                    <HorizBox spread center>
                        <SpacedArray horiz pad={8}>
                            <UtilityText strong label={"Origin:"} />
                            <UtilityText
                                label={linkedQuestion ? linkedQuestion.question : "No origin available"}
                                color={colorTextGrey}
                            />
                        </SpacedArray>
                        <IconButton icon={ArrowUpRight} label={viewLabel} onPress={onPressView} />
                    </HorizBox>
                    {!expanded && <SubtleButton icon={Add} label={"Show more"} onPress={onPressExpand} />}
                    {expanded && expandedSection}
                </View>
            </PadBox>
        </HoverView>
    );

}

function MessageToModerator({ message, commentTime }) {
    const style = StyleSheet.create({
        container: {
            paddingVertical: 16,
            gap: 12,
        },
        headingRow: {
            gap: 8,
            flexDirection: "row",
        },
    });

    if (message != null && message != undefined) {
        return (
            <View style={style.container}>
                <View style={style.headingRow}>
                    <Chat />
                    <UtilityText label={"Message to moderator:"} type="small" strong />
                </View>
                <FormattedDateTime timestamp={commentTime} color={colorTextGrey} />
                <Paragraph text={message} type="small" />
            </View>
        );
    }
}

function CommentDetailSection({ children }) {
    return <View style={ModQueueCommentCardStyle.commentDetailSection}>
        {children}
    </View>
}

function CommentInformation({ comment, modTask }) {
    return (
        <CommentDetailSection>
            <Heading label={"Comment information"} />
            <View style={[ModQueueCommentCardStyle.commentInformationContainer]}>
                <CommentInformationBlock label={"Posted"}>
                    <HorizBox>
                        <Time />
                        <Pad size={4} />
                        <UtilityText label={formatDate(comment.time)} />
                    </HorizBox>
                </CommentInformationBlock>
                <CommentInformationBlock label={"Comment ID"}>
                    <UtilityText text={comment.key} />
                </CommentInformationBlock>
                <CommentInformationBlock label={"Status"}>
                    <JudgementTag modTask={modTask} />
                </CommentInformationBlock>
            </View>
        </CommentDetailSection>
    );
}

function CommentInformationBlock({ children, label }) {
    return <View style={ModQueueCommentCardStyle.commentInformationBlock}>
        <UtilityText strong label={label}></UtilityText>
        {children}
    </View>
}

function NoteFromColleague({ time, text, personaKey }) {
    const authors = useModerationAuthorsByIds([personaKey])
    const author = authors[personaKey];
    if (author) {
        return <View style={{ gap: 12, margin: 8 }}>
            <Byline name={author.name} time={time} photo={author.photoUrl} />
            <Paragraph text={text} type="small" />
        </View>
    }

}

async function saveNote({ dataStore, from, text, modTaskKey }) {
    await dataStore.callServerAsync("moderationZdf", "setModeratorNote", {
        text: text,
        from: from,
        modTaskKey: modTaskKey
    })
}



function NotesFromColleagues({ modTask }) {
    const [modNotes, setModNotes] = useState([]);
    const dataStore = useDatastore()
    const [canWrite, setCanWrite] = useState(false)
    const [text, setText] = useState("")
    const personaKey = usePersonaKey()
    const modNoteCount = useModulePublicData("moderation", ["moderatorNotes", modTask.key, "amount"])

    useEffect(() => {
        async function getNotes() {
            const notes = await dataStore.callServerAsync("moderationZdf", "getModeratorNotes", {
                modTaskKey: modTask.key
            })
            setModNotes(Object.values(notes))
        }
        getNotes()
    }, [modNoteCount])

    const style = StyleSheet.create({
        container: {
            paddingVertical: 16,
            gap: 12
        },
        row: {
            gap: 8,
            flexDirection: "row"
        }
    })

    return <View style={style.container}>
        <View style={style.row}>
            <Heading label={"Internal Notes"} strong />
        </View>
        {modNotes.length > 0 ? modNotes.map((note, idx) => (<NoteFromColleague key={note.key} personaKey={note.from} time={note.time} text={note.text} />)) : null}
        {!canWrite ? <TextFieldButton placeholder='Add your notes...' onPress={() => { setCanWrite(true) }} /> : <View style={{ gap: 8 }}>
            <TextField autoFocus placeholder={"Add your notes..."} value={text} onChange={(newText) => setText(newText)} />
            <HorizBox right center>
                <TextButton label={"Cancel"} onPress={() => {
                    setText("")
                    setCanWrite(false)

                }} />
                <Pad size={28} />
                <CTAButton disabled={text.length <= 0} label={"Post"} type="large" onPress={() => {
                    saveNote({ dataStore: dataStore, from: personaKey, modTaskKey: modTask.key, text: text })
                    setCanWrite(false)
                    setText("")
                }
                } />
            </HorizBox>
        </View>}
    </View>
}

function AIFlag({ modTask }) {
    return (
        <CommentDetailSection>
            <Heading label={"AI flag"} />
            <Banner>
                <View style={{ gap: 16 }}>
                    <RuleViolationList violations={modTask.violations} />
                </View>
            </Banner>
        </CommentDetailSection>
    );
}

function ViolationInformation({ modTask, modAuthor, type = "report" }) {
    return (
        <CommentDetailSection>
            <Heading label={type === "report" ? "User report" : "Human rejected "} />
            <Banner>
                <View style={{ gap: 16 }}>
                    <RuleViolationList violations={type === "report" ? modTask.violations : modTask.reasoning} />
                    <View style={{ gap: 8 }}>
                        <UtilityText label={type === "report" ? "Reported by" : "Rejected by"} type="tiny" />
                        {modAuthor && (
                            <BylineModerationCard
                                photoUrl={type === "report" ? modAuthor.reporterPhoto : modTask.judgePhoto}
                                name={type === "report" ? modAuthor.reporterName : modTask.judgeName}
                                userId={type === "report" ? modAuthor.reporterKey : modTask.judgeKey}
                                size="tiny"
                                SecondaryLineComponent={() =>
                                    FormattedDateTime({ timestamp: modTask.judgementTime ?? modTask.time, color: colorTextGrey })
                                }
                            />
                        )}
                    </View>
                </View>
            </Banner>
        </CommentDetailSection>
    );
}

function RuleViolationList({ violations }) {
    if (violations === "" || violations === undefined || violations === null) {
        violations = ["No information available."];
    }
    else {
        violations = JSON.parse(violations);
        if (violations.length === 0) {
            violations = ["No information available."]
        }
    }

    // Gets rid of duplicate violations
    violations = [...new Set(violations)];

    return (
        <>
            <Heading label={"📌 Community guideline violation"} />
            <View style={{ gap: 12 }}>
                {violations.map((violation, idx) => (
                    <RichText label={"• " + violation} key={"violation-" + idx} />
                ))}
            </View>
        </>
    );
}

function UserInformation({ modAuthor, userId }) {
    const memberSince = () => (
        <HorizBox>
            <UtilityText label={"Member since "} color={colorTextGrey} />
            <UtilityText text={new Date(modAuthor.authorMemberSince).getFullYear().toString()} color={colorTextGrey} />
        </HorizBox>
    );

    return (
        <CommentDetailSection>
            <Heading label={"User information"} />
            <BylineModerationCard
                photoUrl={modAuthor.authorPhoto}
                name={modAuthor.authorName}
                userId={userId}
                size={"large"}
                SecondaryLineComponent={memberSince}
            />
            <View style={ModQueueCommentCardStyle.commentCardProfileStatRow}>
                <View style={ModQueueCommentCardStyle.commentCardNumberContainer}>
                    <Heading text={"40"} />
                </View>
                <UtilityText label={"comments"} />
            </View>
            <View style={ModQueueCommentCardStyle.commentCardProfileStatRow}>
                <View style={ModQueueCommentCardStyle.commentCardNumberContainer}>
                    <Heading text={"5"} />
                </View>
                <UtilityText label={"comments flagged by AI"} />
            </View>
            <View style={ModQueueCommentCardStyle.commentCardProfileStatRow}>
                <View style={ModQueueCommentCardStyle.commentCardNumberContainer}>
                    <Heading text={"1"} />
                </View>
                <UtilityText label={"comments reported by users"} />
            </View>
        </CommentDetailSection>
    );
}

function JudgementTag({ modTask }) {
    let judgementLabel = ModerationFilters.AwaitingDecision.emoji + " " + ModerationFilters.AwaitingDecision.text;
    let isReject = false;
    let isWaiting = true;
    if (modTask.judgement) {
        isReject = modTask?.judgement === 'reject';
        isWaiting = false;

        if (isReject) {
            judgementLabel = ModerationFilters.Rejected.emoji + " " + ModerationFilters.Rejected.text;
        } else {
            if (modTask.judgedBy === "human") {
                judgementLabel = ModerationFilters.HumanApproved.emoji + " " + ModerationFilters.HumanApproved.text;
            } else {
                judgementLabel = ModerationFilters.AutomaticallyApproved.emoji + " " + ModerationFilters.AutomaticallyApproved.text;
            }
        }
    }

    return (
        <View
            style={{
                justifyContent: "center",
                height: 20,
                borderRadius: 32,
                paddingVertical: 2,
                paddingHorizontal: 4,
                backgroundColor:
                    isWaiting === true ? colorBlueBackground : isReject === true ? colorPink : colorLightGreen,
            }}
        >
            <UtilityText label={judgementLabel} strong />
        </View>
    );
}

const ModQueueCommentCardStyle = StyleSheet.create({
    cardContainer: {
        flex: 1,
        minWidth: 0,
        borderRadius: 8,
        boxShadow: "0px 2px 10px 0px #0000001A",
        backgroundColor: colorWhite
    },
    visibilityBar: {
        flexDirection: "row-reverse"
    },
    commentDetailSection: {
        borderTopColor: colorGreyBorder,
        borderTopWidth: 1,
        paddingVertical: 16,
        gap: 24
    },
    commentInformationContainer: {
        backgroundColor: colorWhite,
        flexDirection: "row",
        borderRadius: 11,
        padding: 16,
        gap: 24
    },
    commentInformationBlock: {
        gap: 8
    },
    commentCardProfileStatRow: {
        flexDirection: "row",
        gap: 8,
        alignItems: "center"
    },
    commentCardNumberContainer: {
        backgroundColor: "#D9D9D9",
        borderRadius: 4,
        alignItems: "center",
        justifyContent: "center",
        width: 32,
        height: 32,
    },
    eyeIcon: {
        flexDirection: "row",
        height: 20,
        paddingHorizontal: 6,
        gap: 6,
        backgroundColor: colorPurpleBackground,
        borderRadius: 32,
        alignItems: "center"
    }
})

function BylineModerationCard({ photoUrl, name, userId, size = "tiny", SecondaryLineComponent }) {
    // We have to use the parentDatastore in case the Moderation Card is shown within an embedded instance
    const parentDatatstore = useParentDatastore();

    function onProfile() {
        updateModerationSessionData({
            datastore: parentDatatstore, sessionData: {
                userId: userId
            }
        })
    }

    return (
        <View style={moderationBylineStyle.container}>
            <View>
                <FaceImage type={size} photoUrl={photoUrl} />
            </View>
            <View style={[size === "tiny" ? moderationBylineStyle.row : moderationBylineStyle.column]}>
                <TextButton type='small' alignStart text={name} strong onPress={onProfile} />
                {SecondaryLineComponent && <SecondaryLineComponent />}
            </View>
        </View>
    );
}

const moderationBylineStyle = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    row: {
        flexDirection: 'row',
        gap: 6
    },
    column: {
        flexDirection: 'column',
        gap: 2
    },
});

function FormattedDateTime({ timestamp, locale = null, color = colorBlack }) {
    const date = new Date(timestamp);
    const now = new Date();

    const clientLocale = locale || Intl.DateTimeFormat().resolvedOptions().locale;

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday = date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();

    const optionsTime = { hour: "2-digit", minute: "2-digit" };
    const formattedTime = date.toLocaleTimeString(clientLocale, optionsTime);

    let todayOrYesterdayLabel = "";
    let dateTimeLabel = "";

    if (isToday) {
        todayOrYesterdayLabel = "Today, ";
        dateTimeLabel = formattedTime;
    } else if (isYesterday) {
        todayOrYesterdayLabel = "Yesterday, ";
        dateTimeLabel = formattedTime;
    } else {
        const optionsDate = { day: "2-digit", month: "2-digit", year: "numeric" };
        const formattedDate = date.toLocaleDateString(clientLocale, optionsDate);
        dateTimeLabel = `${formattedDate} ${formattedTime}`;
    }

    return (
        <HorizBox>
            <UtilityText label={todayOrYesterdayLabel} color={color} />
            <UtilityText label={dateTimeLabel} color={color} />
        </HorizBox>
    );
}