import { colorWhite } from "np-platform-client/component/color";
import { UtilityText } from "np-platform-client/component/text";
import { useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "react-native-web";


export function ToolTip({ toolTipWidget, label, children, disabled, offset = 0 }) {
    const [isHovering, setHover] = useState(false)
    const s = StyleSheet.create({
        toolTip: {
            position: "absolute",
            bottom: offset,
            flexDirection: "column",
            height: "fit-content",
            width: "fit-content",
            borderRadius: 8,
            padding: 12,
            backgroundColor: colorWhite,
            shadowColor: "#0000001A",
            shadowOffset: { width: 0, height: 10 },
            shadowRadius: 24,
            zIndex: 1
        },
        tooltipContainer: {
            width: "fit-content"
        }
    })
    return (<View style={s.tooltipContainer} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {isHovering && !disabled && <View style={{position: "relative"}}>
            <View style={s.toolTip}> 
                {toolTipWidget || <UtilityText label={label} />}  
            </View>
        </View>}
        {children}
    </View>
    )
}