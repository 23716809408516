export const firebaseConfig = {
  apiKey: "AIzaSyDX5DlODMm8gYtqT3fj6MuscRlFcys-fDc",
  authDomain: "psi-product-zdfd-deploy-86085.firebaseapp.com",
  databaseURL: "https://psi-product-zdfd-deploy-86085-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "psi-product-zdfd-deploy-86085",
  storageBucket: "psi-product-zdfd-deploy-86085.appspot.com",
  messagingSenderId: "241852901604",
  appId: "1:241852901604:web:da5a2eb5655e46da8524f1"
};

