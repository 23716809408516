import { Narrow, Pad, PadBox } from "np-platform-client/component/basics";
import { CLICK, DemoHeader, DemoSection } from "np-platform-client/component/demo";
import { TabBar, TabContent, TabNavigation } from "../../contrib/zdf/tabnavigation";
import { UtilityText } from "np-platform-client/component/text";
import { View } from "react-native-web";


const tabs = [
    {
        id: "tab-1",
        label: "Tab 1",
        component: Tab1,
    },
    {
        id: "tab-2",
        label: "Tab 2",
        component: Tab2
    },
];

const singleTab = [
    {
        id: "tab-single",
        label: "Single Tab",
        component: SingleTab,
    },
]

function Tab1() {
    return <UtilityText label="This is the first tab." />;
}

function Tab2() {
    return <UtilityText label="This is the second tab." />;
}

function SingleTab() {
    return <UtilityText label="This is the only tab." />;
}

export function TabNavigationDemo() {
    return (
        <Narrow>
            <DemoSection label="TabBar with full width">
                <TabNavigation tabs={tabs} initialTabId={"tab-1"}>
                    <TabBar />
                    <Pad />
                    <TabContent />
                </TabNavigation>
            </DemoSection>
            <DemoSection label="TabBar with width fit to content">
                <TabNavigation tabs={tabs} initialTabId={"tab-1"}>
                    <View style={{ width: "fit-content" }}>
                        <TabBar />
                    </View>
                    <Pad />
                    <TabContent />
                </TabNavigation>
            </DemoSection>
            <DemoSection label="Only 1 tab">
                <TabNavigation tabs={singleTab}>
                    <View style={{ width: "fit-content" }}>
                        <TabBar />
                    </View>
                    <Pad />
                    <TabContent />
                </TabNavigation>
            </DemoSection>
        </Narrow>
    );
}

export function TabNavigationStorySets() {return [
    {
        label: "Tab Navigation Actions",
        instanceKey: "a",
        personaKey: "a",
        sessionData: {},
        globals: null,
        content: (
            <View>
                <TabNavigation tabs={tabs} initialTabId={"tab-1"}>
                    <TabBar />
                    <Pad />
                    <TabContent />
                </TabNavigation>
            </View>
        ),
        serverCall: {
            eventlog: {
                logEvent: () => {},
            },
        },
        stories: [
            {
                label: "Switch tab",
                actions: [CLICK(tabs[1].label), CLICK(tabs[0].label)],
            },
        ],
    },
];}