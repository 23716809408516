import { Narrow } from "np-platform-client/component/basics";
import { DemoSection } from "np-platform-client/component/demo";
import { JudgementCard } from "../../../component/zdf/judgementcard";
import { DemoHeader } from "np-platform-client/system/demo";
import { Datastore } from "np-platform-client/util/datastore";
import { defaultPersonaList, personaListToMap } from "np-platform-client/util/testpersonas";

export function JudgementCardDemo() {
    const modTask0 = { key: "c0", reasoning: "A very fancy reason here", judgement: null } 
    const modTask1 = { key: "c1", reasoning: "A very fancy reason here", judgement:"approve" }
    const modTask2 = { key: "c2", reasoning: "A very fancy reason here", judgement:"reject" }

    const taskWatchStates = {
        "c0": {
            modID: "a",
            startTime: Date.now()
        },
        "c1": {
            modID: "b",
            startTime: Date.now(),
            name:"Thomas Edison"
        },
    }
    return (
        <Datastore personaPreview={personaListToMap(defaultPersonaList)}>
            <Narrow>
                <DemoSection>
                    <DemoHeader label={"Judgement Card - Awaiting decision"} />
                    <JudgementCard modTask={modTask0} />
                </DemoSection>
                <DemoSection>
                    <DemoHeader label={"Judgement Card - Approved comment"} />
                    <JudgementCard modTask={modTask1} />
                </DemoSection>
                <DemoSection>
                    <DemoHeader label={"Judgement Card - Rejected comment"} />
                    <JudgementCard modTask={modTask2} />
                </DemoSection>
                <DemoSection>
                    <DemoHeader label={"View of currently reviewing moderator"}/>
                    <JudgementCard modTask={modTask0} taskWatchState={taskWatchStates.c0}/>
                </DemoSection>
                <DemoSection>
                    <DemoHeader label={"View of different reviewing moderator"} />
                    <JudgementCard modTask={modTask0} taskWatchState={taskWatchStates.c1}/>
                </DemoSection>
            </Narrow>
        </Datastore>
    )
}