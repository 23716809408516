import { DemoSection } from "np-platform-client/component/demo";
import { ModDashboardThreadDetails, ModDashBoardThreadsOverviewList, ModerationDashboardThreadCard } from "../../../component/zdf/moddashboard/moddashboardThreads";
import { defaultPersonaList } from "np-platform-client/util/testpersonas";
import { View } from "react-native-web";


export function ModDashboardThreadCardDemo () {

    const demoThread = {
        key: "t0",
        question: "Is godzilla a serious problem?",
        time: Date.now(),
        commentCount: 100
    }

    const threads = [
        {
            key: "t0",
            from: "a",
            question: "Is godzilla a serious problem?",
            time: Date.now(),
            commentCount: 100
        },
        {
            key: "t1",
            from: "b",
            question: "Is godzilla a serious problem?",
            time: Date.now(),
            commentCount: 100
        },
        {
            key: "t2",
            from: "c",
            question: "Is godzilla a serious problem?",
            time: Date.now(),
            commentCount: 100
        }
        ,{
            key: "t3",
            from: "d",
            question: "Is godzilla a serious problem?",
            time: Date.now(),
            commentCount: 100
        }
    ]

    const author = defaultPersonaList[0]
    const authors = {
        a: defaultPersonaList[0],
        b: defaultPersonaList[1],
        c: defaultPersonaList[2],
        d: defaultPersonaList[3],
    }

    return <>
    <DemoSection>
        <ModerationDashboardThreadCard
            thread={demoThread} 
            mainTag={"Active"}
            secondaryTags={["Conversation Helper", "Video Voting"]}
            commentCount = {100}
            author={author}
        />
    </DemoSection>
    <DemoSection>
        <ModDashBoardThreadsOverviewList threads={threads} authors={authors} />
    </DemoSection>
    <DemoSection>
        <View style={{backgroundColor:"#F7F7F7"}}>
            <ModDashboardThreadDetails thread={demoThread} threadAuthor={author}/>
        </View>
    </DemoSection>
    </>
}