
export const ui_translations_french = {
    'Join the Conversation': 'Rejoignez la conversation',
    "Join the conversation": "Rejoignez la conversation",
    "Join the conversation to enter": "Rejoignez la conversation pour entrer",
    "Join the conversation to answer": "Rejoignez la conversation pour repondre",
    'question': 'question',
    'There {isAre} {count} {noun} being discussed': 'Il y a {count} {noun} en discussion',
    '🚀 Read More': '🚀 Lire la suite',
    'Related questions': 'Questions connexes',
    'Ask a question related to this topic, or join the conversation by submitting an answer. Be sure to follow our [community guidelines](https://www.google.com).': 'Posez une question liée à ce sujet ou participez à la conversation en soumettant une réponse. Assurez-vous de suivre nos [règles de la communauté](https://www.google.com).',
    'Ask a question': 'Poser une question',
    'answer': 'réponse',
    'answers': 'réponses',
    'Your answer is posted': 'Votre réponse est publiée',
    'story': 'histoire',
    'Community Guidelines': 'Lignes directrices de la communauté',
    'Loading...': 'Chargement...',
    'Filter by opinion': 'Filtrer par opinion',
    'Posting...' : 'Publication...',
    'Loading questions...': 'Chargement des questions...',
    'questions': 'questions',
    'Read more': 'Lire la suite',
    'Upvoted ({count})': 'Voté positif ({count})',
    'Log in to answer': 'Connectez-vous pour répondre',
    'No more comments': 'Pas plus de commentaires',
    'No more responses': 'Pas plus de réponses',
    'Save': 'Enregistrer',

    // Moderation
    '🚧 **Moderation under construction:** We are still tuning auto-moderator triggering.': '🚧 **Modération en construction:** Nous sommes toujours en train de régler le déclenchement de l\'auto-modérateur.',
    'Consider rewording your post': 'Envisagez de reformuler votre message',
    'Your post violates our [Community Guidelines](https://google.com).': 'Votre message viole nos [Règles de la communauté](https://google.com).',
    'You are free to express any opinion, however you must do so in a respectful way that avoids personal attacks, assumes good intent, and backs up contentious claims with sources.': 'Vous êtes libre d\'exprimer n\'importe quelle opinion, cependant vous devez le faire de manière respectueuse, en évitant les attaques personnelles, en supposant une bonne intention et en étayant les affirmations controversées par des sources.',
    'Sometimes our AI moderator triggers incorrectly. If that happened then first try adjusting your wording to be more obviously respectful. If you are still unable to get the moderator to accept a respectfully expressed opinion then please let us know.': 'Parfois, notre modérateur AI se déclenche de manière incorrecte. Si cela s\'est produit, essayez d\'abord d\'ajuster vos mots pour qu\'ils soient plus évidemment respectueux. Si vous êtes toujours incapable de faire accepter un avis exprimé de manière respectueuse, veuillez nous le faire savoir.',
    'To provide tranparency, we offer an anonymized [public database](https://google.com) of all moderation decisions.': 'Pour assurer la transparence, nous proposons une [base de données publique](https://google.com) anonymisée de toutes les décisions de modération.',
    'Discuss with Human Moderator': 'Discuter avec le modérateur humain',
    'Updating...': 'Mise à jour...',
    'Your response has been sent to a moderator for review': 'Votre réponse a été envoyée à un modérateur pour examen',
    'You will be notified when the moderator makes a decision. In the meantime, you can look at the [transparency dashboard]({dashboardUrl}) to see where your post is in our moderation queue.': 'Vous serez informé lorsque le modérateur prendra une décision. En attendant, vous pouvez consulter le [tableau de bord de transparence]({dashboardUrl}) pour voir où se trouve votre message dans notre file d\'attente de modération.',
    'I understand': 'Je comprends',
    'We strive for a kind and constructive discussion space where everyone feels welcome to contribute their perspectives and lived experiences to the conversation. This discussion space values responses that are on-topic and relevant to the subject matter, including meaningful debate.':'Nous souhaitons créer un espace de discussion agréable et constructif où chacun est à l’aise d’apporter son point de vue et son expérience à la conversation. Ce lieu valorise les échanges pertinents, y compris des débats, qui portent sur le sujet de discussion.',
    'To foster a welcoming and respectful environment, this discussion space uses AI-aided moderation to enforce the following community guidelines:': 'Afin de favoriser un environnement accueillant et respectueux, cet espace utilise la modération assistée par l’intelligence artificielle pour faire respecter les lignes directrices suivantes :',
    'No uncivil, hateful, or illegal content':'Aucun contenu irrespectueux, haineux ou illégal',
    'No comments that call for or celebrate harm': "Aucun commentaire appelant ou incitant à la violence",
    'No insults, mockery, threats, or personal attacks': "Pas d'insultes, de moqueries, de menaces, ou d'attaques personnelles",
    'Refrain from hostile comments that attach negative labels to, or assume bad intent of specific groups of people':"S'abstenir de commentaires hostiles qui attribuent des étiquettes négatives à des groupes spécifiques de personnes ou qui leur prêtent de mauvaises intentions",
    'Back up contentious claims with sources': "Pas de contenu ou d’argumentaire basé sur de fausses affirmations ou de fausses nouvelles; les affirmations litigieuses doivent être étayées par des sources crédibles",
    'No spam or commercial content': "Votre contenu doit être de nature personnelle, et non de nature commerciale, et ne doit pas inclure de messages non sollicités (spam).",
    "Your post may have violated the following Community Guidelines:": "Votre message peut avoir violé les lignes directrices suivantes :",
    "Need help?": "Besoin d'aide ?",
    "Thank you!": "Merci !",
    'Your response has been sent to a moderator for review.': 'Votre réponse a été envoyée au modérateur pour la révisée.',
    "📫 What happens next": "Que se passe-t-il ensuite",
    '✨ We use AI for moderation': '✨ Nous utilisons l\'IA pour la modération',
    'AI helps us to manage moderation, but sometimes it triggers incorrectly. If you are unable to get our AI to accept a respectfully expressed opinion, please send it to our moderators for review.': 'L\'IA nous aide à gerer la modération, mais certainement, il se fait mal. Si vous ne pouvez pas obtenir l\'IA à accepter une opinion en consciences, envoyez-la au modérateur pour la révisée.',
    "Message to moderator...": "Message au modérateur...",
    'Please explain why we should publish your post.': 'Veuillez expliquer pourquoi nous devons publier votre message.',
    'Done': 'Fait',
    'Respect the privacy of others and do not include personal information in your contributions': "Respectez la vie privée des autres en n'incluant dans votre contenu aucun renseignement personnel (comme un numéro de téléphone ou une adresse de courriel) ni conversation privée",

    // Comment Slider
    'Strongly no': 'Non catégorique',
    'No with reservations': 'Plutôt non',
    "It's complicated": "C'est complexe",
    'Yes with reservations': "Plutôt oui",
    'Strongly yes': "Oui catégorique",
    'Clear Filter': 'Effacer le filtre',
    'Your current public response': 'Votre réponse publique actuelle',
    'Showing {responseCount} public responses and {voteCount} anonymous votes': 'Affichage de {responseCount} réponses publiques et {voteCount} votes anonymes',
    'Tap to respond': 'Appuyez pour répondre',
    'Slide to respond:': 'Faites glisser pour répondre:',
    'Explain your response (optional)': 'Expliquez votre réponse (facultatif)',
    'Public response': 'Réponse publique',
    'Anonymous vote': 'Vote anonyme',
    'Agree/Disagree': 'D\'accord/Pas d\'accord',
    'Explain your response': 'Expliquez votre réponse',
    'Share your thoughts... (optional)': 'Partagez vos pensées... (facultatif)',

    // Question Screen
    'responses': 'réponses',
    'response': 'réponse',
    'Update your response': 'Mettre à jour votre réponse',
    'Only visible to you': 'Visible uniquement par vous',
    '🔍 Under Review': '🔍 En cours de révision',
    'Share': 'Partager',
    'Your current response': 'Votre réponse actuelle',
    'There are no responses yet. Start the conversation!': 'Il n\'y a pas encore de réponses. Commencez la conversation!',

    // Single Question
    'Choose a Question (Admin)': 'Choisir une question (Admin)',
    'Choose a question': 'Choisir une question',
    'Ask a question...' : 'Poser une question...',
    'Choose': 'Choisir',
    'Chosen': 'Choisi',
    'Choose a different question (admin)': 'Choisir une autre question (admin)',
    'Write your answer...': 'Écrivez votre réponse...',
    'no answers yet': 'aucune réponse pour l\'instant',

    // Multi question
    'Choose Questions for this Article': 'Choisir des questions pour cet article',
    'Edit questions (admin)': 'Modifier les questions (admin)',
    'Related Conversations': 'Conversations connexes',
    'Add Question': 'Ajouter une question',
    'Editorial Question': 'Question éditoriale',
    'Adding...' : 'Ajout...',
    'Related Conversation': 'Conversation connexe',
    'Write the first response': 'Écrivez la première réponse',
    'Join': 'Rejoindre',

    // Feature Module Names
    'Comment Slider': 'Curseur de commentaire',
    'Article Questions': 'Questions d\'article',
    'Pre-Moderation': 'Pré-modération',
    'Question Byline': 'Ligne de question',
    'Reaction Types': 'Types de réaction',
    'Narwhal Reactions': 'Réactions Narwhal',

    // feature poll
    'Select one option': 'Sélectionner une option',
    'Your response': 'Votre réponse :',
    'Submit my vote': 'Soumettre',
    'Change my vote': 'Modifier ma réponse',
    'Other': 'Autre',
    'You': 'Vous',
    'Write in a topic...': 'Rédiger un sujet...',

    // Topic admin form
    'Create a topic': 'Créer un sujet du moment',
    'Topic name': 'Titre du sujet du moment',
    'Starts at': 'Date de début',
    'Ends at': 'Date de fin',
    '(YYYY-MM-DD format)': '(Format AAAA-MM-JJ)',
    'Conversation starter': "Question aux utilisateurs",
    'Is active': 'Actif',
    'Active': 'Actif',
    'Add topic': 'Créer le sujet du moment',
    'Topic poll': 'Sondage',
    'Upcoming topic poll': 'Sondage pour le prochain sujet du moment',
    'Poll question': 'Question du sondage',
    'Open answer label': 'Titre du choix de réponse ouvert',
    'Open answer placeholder': 'Placeholder du choix de réponse ouvert',
    'Remove option': 'Retirer un choix de réponse',
    'Add option': 'Ajouter un choix de réponse',
    'Option...': 'Choix de réponse...',
    'Edit topics (admin)': 'Modifier les sujets (admin)',


    // Moderation
    '**Avoid uncivil or disrespectful language**. You can express any opinion, however you must do so in a respectful way.': '**Évitez le langage incivil ou irrespectueux**. Vous pouvez exprimer n\'importe quelle opinion, mais vous devez le faire de manière respectueuse.',
    '**Avoid calls for harm or celebration of harm**. You can advocate for actions that would have negative consequences for some people, but harm should not be the primary goal.': '**Évitez les appels au mal ou la célébration du mal**. Vous pouvez plaider en faveur d\'actions qui auraient des conséquences négatives pour certaines personnes, mais le mal ne devrait pas être l\'objectif principal.',
    '**Do not imply that someone has bad intent**. Assume they have good intentions and critique their ideas and their actions instead.': '**Ne laissez pas entendre que quelqu\'un a de mauvaises intentions**. Supposez qu\'ils ont de bonnes intentions et critiquez plutôt leurs idées et leurs actions.',
    '**Avoid attacking the character of others**. Critique their ideas and their actions instead.': '**Évitez d\'attaquer le caractère des autres**. Critiquez plutôt leurs idées et leurs actions.',
    '**Avoid snark, sarcasm, and mockery**': '**Évitez le sarcasme, la moquerie et la dérision**',
    '**Controversial claims must provide a source URL**': '**Les affirmations controversées doivent fournir une URL source**',
    '**Be sure to write your response in a way that those who disagree with will be willing to listen to**': '**Assurez-vous d\'écrire votre réponse de manière à ce que ceux qui ne sont pas d\'accord soient prêts à écouter**',

    '⚠️ Take another look': '⚠️ Revoir',
    'Your post may violate our [Community Guidelines](https://example.com).': 'Votre message peut violer nos [Règles de la communauté](https://example.com).',
    '**Sometimes our AI triggers incorrectly**': '**Parfois, notre AI se déclenche de manière incorrecte**',
    'If that happened then first try adjusting your wording to be more obviously respectful. If you are still unable to get the AI to accept a respectfully expressed opinion then please send it to our human moderator.': 'Si cela s\'est produit, essayez d\'abord d\'ajuster vos mots pour qu\'ils soient plus évidemment respectueux. Si vous êtes toujours incapable de faire accepter un avis exprimé de manière respectueuse, veuillez le transmettre à notre modérateur humain.',
    'To provide transparency, we offer an anonymized [public database]({dashboardUrl}) of all moderation decisions.': 'Pour assurer la transparence, nous proposons une [base de données publique]({dashboardUrl}) anonymisée de toutes les décisions de modération',
    'Send to moderator': 'Envoyer au modérateur',
    'Message to moderator*': 'Message au modérateur*',
    'Please explain your response (required)': 'Veuillez expliquer votre réponse (obligatoire)',
    'Rejected': 'Rejeté',
    'Approved': 'Approuvé',
    'Awaiting Decision': 'En attente de décision',
    'Moderation Queue': 'File d’attente de modération',
    'Moderator Explanation': 'Explication du modérateur',
    'Explain your decision...': 'Expliquez votre décision...',
    'Approve': 'Approuver',
    'Reject': 'Rejeter',
    '**Message to moderator**: {appealText}': 'Message au modérateur : {appealText}',
    'Moderation Transparency Dashboard': 'Tableau de bord de transparence de la modération',

    // Fonctionnalité Blacklist
    'Blacklisted Terms': 'Termes de la liste noire', 
    'Blacklist is empty.': 'La liste noire est vide.', 
    'Blacklist': 'Liste noire' ,
    'Select': 'Sélectionner', 
    'Deselect': 'Désélectionner', 
    'Please enter at least one valid term.': 'Veuillez entrer au moins un terme valide.', 
    'The following term(s) already exist in the blacklist.': 'Les termes suivants existent déjà dans la liste noire.',
    'Adding term(s)...': 'Ajout des termes...', 
    'Added term(s) successfully!': 'Termes ajoutés avec succès !', 
    'Deleting term(s)...': 'Suppression des termes...', 
    'Deleted term(s) successfully!': 'Termes supprimés avec succès !',
    'Term(s) are already in blacklist': 'Le(s) terme(s) figure(nt) déjà dans la liste noire',
    'Enter new terms, separated by new lines': 'Entrez de nouveaux termes, séparés par des nouvelles lignes', 
    'Add to blacklist': 'Ajouter à la liste noire', 
    'Hold Shift and double-click to select or deselect multiple terms.': 'Maintenez Maj et double-cliquez pour sélectionner ou désélectionner plusieurs termes.', 
    'Delete selected': 'Supprimer les termes sélectionnés', 
    'Delete terms?': 'Supprimer les termes ?',
    'Do you really want to delete the selected term(s)? This action cannot be undone.': 'Voulez-vous vraiment supprimer les termes sélectionnés ? Cette action est irréversible.', 
    'Cancel': 'Annuler', 
    'Delete': 'Supprimer',

    // Reactions
    'Thank you': 'Merci',
    'New to me': 'Nouveau pour moi',
    'Respect': 'Respect',
    'Disagree': 'Pas d\'accord',
    'Best': 'Meilleur',
    'Provocative': 'Provocateur',
    'Clarifying': 'Éclairant',

    // Representing Perspectives
    'Perspective Editor (Admin)': 'Éditeur de perspectives (Admin)',
    'Perspective List': 'Liste des perspectives',
    'What perspectives are important to this conversation? (newline separated)': 'Quelles perspectives sont importantes pour cette conversation? (séparées par des sauts de ligne)',
    "Add your perspective": "Ajoutez votre perspective",
    "Perspectives": "Perspectives",
    "We haven't heard from these perspectives yet": "Nous n'avons pas encore entendu ces perspectives",
    'Add a response': 'Ajouter une réponse',
    'How do you relate to this issue?': 'Comment vous rapportez-vous à ce problème?',
    'Sort by perspective': 'Trier par perspective',
    'Apply': 'Appliquer',
    'Show more responses': 'Afficher plus de réponses',
    'Edit perspectives (Admin)': 'Modifier les perspectives (Admin)',
    'What perspectives do you want to see first?': 'Quelles perspectives voulez-vous voir en premier?',
    "We'd particularly like to hear from:": "Nous aimerions particulièrement avoir l'opinion de :",
    "Add your own": "Ajouter votre propre",
    "Write in your own": "Ecrivez dans votre propre",
    'Write in a perspective': 'Ecrivez dans une perspective',
    
    // Conversation History
    'Conversation history': 'Historique de la conversation',
    'Visible to others': 'Visible aux autres',
    'Your conversation history is always visible to admins and moderators.': 'Votre historique de conversation est toujours visible par les administrateurs et les modérateurs.',
    'Visible only to admins': 'Visible uniquement par les administrateurs',
    'This user has not made their conversation history public.': 'Cet utilisateur n\'a pas rendu son historique de conversation public.',

    // Other
    'No main screen defined.': 'Aucun écran principal défini.',

    // ToM
    'Live': 'En cours',
    'Ended': 'Terminé',
    'Follow': 'Suivre',
    'Following': 'Suivi',
    'Post': 'Publier',
    'Topic of the moment': 'Sujet du moment',
    'participant': 'personne participante',
    'participants': 'personnes participantes',
    'Ask us a question...': 'Réagir ou poser une question...',
    'Your question will be considered for future content pieces. Please note, we may not be able to answer all questions.': 'Vos contributions pourraient inspirer de futurs contenus. Il est possible que nous ne puissions pas répondre à chacune d’elles.',
    'Popular questions': 'Les plus populaires',
    'View all questions': 'Tout afficher',
    'Be the first to share your thoughts.': 'Ouvrez le bal!\nSoyez la première personne à s’exprimer sur le sujet.',
    'See all': 'Toutes les contributions',
    'Discuss this topic with other citizens': 'On en parle aussi...',
    'Response': 'réponse',
    'Responses': 'réponses',
    'Need more information to make up your mind?': 'Envie de creuser le sujet?',
    'Previous': 'Précédent',
    'Next': 'Suivant',
    'Previous topics covered': 'Des sujets qui ont fait jaser',
    'No more questions': 'C’est tout pour le moment.',
    'All questions': 'Toutes les contributions',
    'Filters': 'Filtres',
    'Filter': 'Filtrer',
    'Sort by': 'Filtrer par',
    'Most recent': 'Plus récentes',
    'Oldest': 'Plus anciennes',
    'Most popular': 'Plus populaires',
    
    // Conversation Helper
    "Help history": "Historique de l'aide",
    "Conversation Help": "Aide à la conversation",
    "Hello everyone!": "Bonjour à tous !",
    "Open dashboard (admin)": "Ouvrir le tableau de bord (admin)",
    "Conversation Helper": "Aide à la conversation",
    "Add-ons": "Extensions",
    "Reactions": "Réactions",
    "Help History": "Historique de l'aide",
    "Conversation Summary": "Résumé de la discussion",
    "Private Comment Feedback": "Réaction privée au commentaire",
    "Timing of Analysis": "Moment de l'analyse",
    "Automatic analysis": "Analyse automatique",
    "Analyze after every...": "Analyser après chaque...",
    "1 comment": "Commentaire",
    "3 comments": "3ème commentaire",
    "5 comments": "5e commentaire",
    "10 comments": "10e commentaire",
    "Only manual analysis": "Analyse manuelle seulement",
    "Update guidance if there are no problems": "Actualise la note s'il n'y a pas de problème",
    "Update based on comments...": "Actualiser sur la base des commentaires...",
    "Never": "Jamais",
    "Every comment": "Après chaque commentaire",
    "Every 3 comments": "Tous les 3 commentaires",
    "Every 5 comments": "Tous les 5 commentaires",
    "Every 10 comments": "Tous les 10 commentaires",
    "Update based on time...": "Actualiser sur la base du temps...",
    "Immediately": "Immédiatement",
    "Every 10 min": "Toutes les 10 minutes",
    "Every 30 min": "Toutes les 30 minutes",
    "Every hour": "Chaque heure",
    "Scope of Analysis": "Étendue de l'analyse",
    "Analyze...": "Analyser...",
    "All comments": "Tous les commentaires",
    "Most recent x% of comments": "Derniers x% des commentaires",
    "New comments since last analysis": "Nouveaux commentaires depuis la dernière analyse",
    "Use percentage...": "Choisir le pourcentage...",
    "Most recent 10%": "Derniers 10%",
    "Most recent 25%": "Derniers 25%",
    "Most recent 50%": "Derniers 50%",
    "Apply percentage...": "Utiliser des pourcentages...",
    "Always": "Toujours",
    "After 5 comments": "Après 5 commentaires",
    "After 10 comments": "Après 10 commentaires",
    "Auto-Moderator Personality": "Personnalité de l'auto-modérateur",
    "Use personality...": "Utilise la personnalité...",
    "Personality A": "Personnalité A",
    "Personality B": "Personnalité B",
    "Personality C": "Personnalité C",
    "Conversation Helper Dashboard": "Tableau de bord de l'aide à la conversation",
    "Related Thread": "Conversation associée",
    "Current Guidance": "Note d'aide actuelle",
    "This guidance message is visible to everyone.": "Cette note est visible par tous.",
    "This message was generated because...": "Cette note a été générée parce que...",
    "Generate new guidance": "Générer une nouvelle note",
    "Conversation Analysis": "Analyse de la conversation",
    "Analyze conversation": "Analyser la conversation",
    "Result: ": "Résultat: ",
    "Problems detected!": "Problèmes détectés !",
    "No problems detected.": "Aucun problème n'a été détecté.",
    "Explanation": "Explication",
    "Conversation Stats": "Statistiques de conversation",
    "- Last conversation analysis: **{time}**": "- Dernière analyse de l'entretien: **{time}**",
    "- Last conversation analysis: **None**": "- Dernière analyse de l'entretien: **Aucune**",
    "- **{count} check(s)** for problems performed": "- **{count} analyse(s) de conversation** réalisée(s)",
    "- **{count} guidance message(s)** generated": "- **{count} note(s)** générée",
    "- **{count} new comment(s)** since last conversation analysis": "- **{count} nouveau(x) commentaire(s)** depuis la dernière analyse",

    // VVT
    'Enable voting': 'Autoriser le vote',
    'Vote anonymously': 'Vote anonyme',
    'Start Video': 'Lancer la vidéo',
    'This video contains audience questions that you can participate in. You can vote anonymously, skip individual questions, or disable this feature. In the ' :
    'Cette vidéo contient des questions de spectateurs auxquelles tu peux participer. Tu peux désactiver cette fonction, voter de manière anonyme et ignorer certaines questions. À propos de la ',
    'settings': 'Préférences',
    ' you can deactivate it for all videos.': ' tu peux désactiver cette fonction de manière générale.',
    "You have to be logged in to use this feature.\nThe video will play as usual and you won't see any votings.\n**Click here to log in**." : 
    "Vous devez être connecté pour utiliser cette fonction.\nLa vidéo sera lue comme d'habitude et vous ne verrez pas les votes.\n**Cliquez ici pour vous connecter**.",

    // Video Voting - Voting flow
    'Discuss with others': "Echange avec d'autres",
    'Continue': "Continuer",
    'Take a look at the voting in the overview': "Consulter l'aperçu du vote",
    "What do you think about it now?": "Qu'en penses-tu maintenant ?",

    // Video Voting - Sidebar
    'Video Voting': 'Vote par vidéo',
    'Voting Results': 'Résultats des votes',
    'All responses': 'Tous les commentaires',
    'Votings': 'Votes',
    "Time:": "Temps:",
    'END': 'Fin',
    "No voting data available.": "Aucune donnée de vote disponible",
    'Go to video': 'Voir la vidéo',

    // Video Voting - User profile
    "Settings": "Préférences",
    "Enable voting for all videos": "Activer le vote pour toutes les vidéos",

    // Video Voting - Administrative text
    'Manage Video Voting (Admin)': 'Gérer le vote vidéo (Admin)',
    "Please create default question threads first.": "Veuillez d'abord créer les fils de questions standard.",
    "Create Default Questions": "Créer des questions standard",
    "Create a Video Voting for this Article": "Crée un vote vidéo pour cet article",
    "This article does not have a video voting yet.": "Cet article n'a pas encore fait l'objet d'un vote vidéo.",
    "Individual Questions": "Questions individuelles",
    "Connected Questions": "Questions sur l'évolution",
    "Endometriosis (German)": "Endométriose (en allemand)",
    "Endometriosis (English)": "Endométriose (en anglais)",
    "Open relationships (German)": "Relations ouvertes (en allemand)",
    "Open relationships (English)": "Relations ouvertes (en anglais))",
    "Create Video Voting": "Création d'un vote vidéo",
    "Delete Video Voting": "Supprimer le vote vidéo",
    "The video voting template is missing": "Le modèle de vote vidéo manque",
    "Video voting is not enabled for this video.": "Le vote vidéo n'est pas activé pour cette vidéo.",
    'Video voting is enabled for this video.' : 'Le vote vidéo est activé pour cette vidéo.',
    'Video voting is enabled for this video. To participate, please start the video.': 'Le vote vidéo est activé pour cette vidéo. Pour participer, tu dois être connecté.',

    // Context Entry Points
    "You are joining from:": "Tu rejoins depuis :",
    "Joining from:": "Rejoins depuis :",
    "Read article": "Lire l'article",

    // Moderation Dashboard
    'Message to moderator:': 'Message au modérateur :',
    "Today, ": "Aujourd'hui, ",
    "Yesterday, ": "Hier, "
 }


