import { AddComment, Chat, ChevronLeft, Icon, Settings } from "@carbon/icons-react"
import { HorizBox, HoverView, Pad, PadBox, WindowTitle, LoadingScreen } from "np-platform-client/component/basics"
import { DropDownSelector, TextButton } from "np-platform-client/component/button"
import { colorGreyBorder, colorGreyHover, colorGreyPopupBackground, colorRed, colorSecondaryPress, colorTextGrey } from "np-platform-client/component/color"
import { FaceImage, ProfilePhoto } from "np-platform-client/component/people"
import { Heading, UtilityText } from "np-platform-client/component/text"
import { Dimensions, ScrollView, StyleSheet } from "react-native"
import { Image, View } from "react-native-web"
import React, { useEffect, useState } from "react"
import { SpacedArray } from "np-platform-client/component/demo"
import { useDatastore, useInstanceContext, useInstanceKey, useModulePublicData, usePersonaKey, usePersonaObject, useSessionData, useStructureKey } from "np-platform-client/util/datastore"
import { firebaseSignOut } from "np-platform-client/util/firebase"
import { gotoUrl } from "np-platform-client/util/url"
import { getScreenStackForUrl, gotoInstance, pushSubscreen } from "np-platform-client/util/navigate"
import { imagesZdf } from "../../assets/zdf"
import { ModerationCommentOverviewAll } from "../../component/zdf/modcommentoverview"
import { ModDashBoardThreadsOverview } from "../../component/zdf/moddashboard/moddashboardThreads"
import { REPLACE_ZDF_DropDownSelector } from "../../component/zdf/dropdownselector"
import { ModerationDashboardUserProfile } from "../../component/zdf/moddashboard/modDashboardProfile"
import { useScreenParams } from "np-platform-client/util/params"
import { useModerationAuthorById } from "../../component/moderation"

export const ModDashboardStructure = {
    key: 'moddashboard',
    name: 'Moderation Dashboard',
    screen: ModerationHomeScreen,
    subscreens: {
    },
    defaultConfig: {
        openLinksInNewTab: true
    }
}

const moderationPages = [
    {
        id: "page-threads",
        label: "Threads",
        icon: AddComment,
        component: ModDashBoardThreadsOverview,
    },
    {
        id: "page-comments",
        label: "Comments",
        icon: Chat,
        component: ModerationCommentsPage
    },
    {
        id: "page-settings",
        label: "Settings",
        icon: Settings,
        component: ModerationSettingsPage
    }
];

export function ModerationInitializer() {
    const datastore = useDatastore();
    const moderationSessionData = useModerationSessionData();
    const personaKey = usePersonaKey();

    const params = useScreenParams()

    useEffect(() => {
        if (!moderationSessionData) {
            initializeModerationSession();
        }
    }),
        [moderationSessionData, personaKey];

    function initializeModerationSession() {
        let tabId = moderationPages[0].id;

        // Grab tab id from url paramters
        if (params.tab) {
            tabId = params.tab
        }

        let initialSessionData = { pageId: tabId };
        let threadId = null;
        if (params.thread) {
            tabId = params.thread
            initialSessionData = { ...initialSessionData, openedThread: threadId };
        }

        let userId = null;
        if (params.userId) {
            userId = params.userId
            initialSessionData = { ...initialSessionData, userId: userId };
        }

        setModerationSessionData({ datastore, sessionData: initialSessionData });
    }
}

export function ModerationHomeScreen() {

    const moderationSessionData = useModerationSessionData();
    const pageIndex = moderationSessionData?.pageId

    // Should a user profile be shown?
    const userId = moderationSessionData?.userId
    const user = useModerationAuthorById(userId);
    const CurrentModerationPage = moderationPages.find((page) => (page.id === pageIndex))?.component;

    return (
        <>
            <WindowTitle title={"Moderation Dashboard"} />
            <ModerationInitializer />
            <View style={{ flexDirection: "row", flexGrow: 1, width: "100%" }}>
                {moderationSessionData ? <ModerationMainMenu initialPageIndex={pageIndex} /> : <LoadingScreen />}
                <View style={{ flexGrow: 1, flexShrink: 1 }}>
                    <ModerationHeader />
                    {/* TODO: Find a better way to set this to fill the remaining height */}
                    <View style={{ height: "calc(100vh - 80px - 56px)", overflow: "hidden", backgroundColor: colorGreyPopupBackground }}>
                        {/* User profile can be opened from anywhere */}
                        {userId && <View style={{ overflow: "scroll", height: "100%", paddingHorizontal: 104 }}>
                            <ModerationDashboardUserProfile key={userId} user={{ ...user, key: userId }} />
                        </View>
                        }
                        {/* When user profile is open hide the previous content, to not reset their state */}
                        <View style={{ overflow: "scroll", height: "100%", visibility: userId ? "hidden" : "visible", position: userId ? "absolute" : "static", paddingHorizontal: 104 }}>
                            {CurrentModerationPage ? <CurrentModerationPage /> : <LoadingScreen />}
                        </View>
                    </View>
                </View>
            </View>
        </>
    );
}

export function ModerationMainMenu() {
    const datastore = useDatastore();

    const personaKey = usePersonaKey();
    const persona = usePersonaObject(personaKey);
    const isLoggedIn = personaKey !== undefined && personaKey !== null;

    const moderationSessionData = useModerationSessionData();
    const pageId = moderationSessionData ? moderationSessionData.pageId : moderationPages[0].id;
    const moderationSession = useModerationSessionData()


    const switchTab = (tabId) => {
        if (moderationSession?.userId) {
            updateModerationSessionData({
                datastore, sessionData: {
                    userId: null
                }
            })
        }
        let url = new URL(window.location);
        let params = url.searchParams;
        params.set("tab", tabId);
        params.delete("thread");
        gotoUrl(url.toString());
        updateModerationSessionData({ datastore, sessionData: { pageId: tabId } });
    }

    const s = StyleSheet.create({
        sideBar: {
            display: "flex",
            flexDirection: "column",
            margin: "0px",
            width: "fit-content",
            padding: 40,
            borderRightWidth: 1,
            borderRightColor: colorGreyBorder,
            borderRadius: 1,
            borderStyle: "solid",
            justifyContent: "space-between"
        },
    });

    return (
        <View style={s.sideBar}>
            <View>
                <Heading label={"PSI Moderation"} level={2} />
                <Pad size={40} />
                <SpacedArray pad={16}>
                    {
                        moderationPages.map((page, i) => (
                            <ModerationMenuButton
                                key={"tab-" + i}
                                icon={page.icon}
                                label={page.label}
                                onPress={() => { switchTab(page.id) }}
                                selected={pageId === page.id}
                            />
                        ))
                    }
                </SpacedArray>
            </View>
            <HorizBox center>
                <ProfilePhoto type="extraLarge" userId={personaKey} />
                <PadBox horiz={8} />
                <View>
                    <UtilityText type="large" label={persona.name ?? "No user logged in"} />
                    {isLoggedIn ?
                        <TextButton color={colorTextGrey} type="tiny" label={"Log out"} onPress={() => { firebaseSignOut(); }} />
                        : <TextButton color={colorTextGrey} type="tiny" label={"Log in"} onPress={() => { datastore.gotoInstance({ structureKey: 'login', instanceKey: 'one' }); }} />
                    }
                </View>
            </HorizBox>
        </View>
    );
}

export function ModerationCommentsPage() {
    return <ModerationCommentOverviewAll />;
}

export function ModerationSettingsPage() {
    return <UtilityText label="Settings" />
}

export function ModerationMenuButton({ icon, label, onPress, showNotification = false, selected = false }) {
    const s = StyleSheet.create({
        tile: {
            width: "220px",
            height: "48px",
            borderRadius: 32,
            paddingHorizontal: 12,
            userSelect: "none",
            justifyContent: "center"
        },
        hover: {
            backgroundColor: colorGreyHover
        },
        selected: {
            backgroundColor: colorGreyPopupBackground
        },
        press: {
            backgroundColor: colorSecondaryPress
        },
        notificationBubble: {
            backgroundColor: colorRed,
            width: "8px",
            height: "8px",
            borderRadius: "50%",
        },
    });

    return (
        <View>
            <HoverView
                style={[s.tile, selected ? s.selected : null]}
                hoverStyle={s.hover}
                pressedStyle={s.press}
                onPress={onPress}
            >
                <View>
                    <HorizBox center>
                        {React.createElement(icon, { width: 30, height: 30 })}
                        <Pad size={8} />
                        <UtilityText center label={label} />
                        <Pad size={8} />
                        <View style={showNotification ? s.notificationBubble : null}></View>
                    </HorizBox>
                </View>
            </HoverView>
        </View>
    );
}

export function ModerationHeader() {
    const datastore = useDatastore();
    const moderationSession = useModerationSessionData()
    const subPageOpen = moderationSession?.openedThread || moderationSession?.userId

    const backLabel = moderationSession?.userId ? "Back to comment" : "Back to threads"

    const params = useScreenParams()
    const structureKey = useStructureKey()
    const instanceKey = useInstanceKey();

    const goBack = () => {
        if (moderationSession?.userId) {
            updateModerationSessionData({
                datastore, sessionData: {
                    userId: null
                }
            })
            params.userId = null;
            gotoInstance({ structureKey, instanceKey, params })
        } else {
            updateModerationSessionData({
                datastore, sessionData: {
                    openedThread: null,
                }
            })
            params.thread = null;
            gotoInstance({ structureKey, instanceKey, params })
        }
    }

    return <View style={{ height: 80, borderBottomWidth: 1, borderColor: colorGreyBorder, justifyContent: "center", paddingLeft: 100, paddingRight: 40 }}>
        <HorizBox spread center>
            {subPageOpen ? <TextButton leftIcon={ChevronLeft} label={backLabel} color={colorTextGrey} type="tiny" onPress={goBack} /> : <View></View>}
            {moderationSession?.userId ? <View /> : <ChannelSelection />}
        </HorizBox>
    </View>
}

function ChannelSelection() {
    const [dropDownValue, setDropDownValue] = useState(null);
    const options = [
        { key: 'heute', label: 'ZDF heute' },
    ]
    return <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
        <UtilityText label={"Channels:"} strong />
        <FaceImage type="tiny" photoUrl={imagesZdf.moddashboardZDFHeute} />
        <View>
            <REPLACE_ZDF_DropDownSelector options={options} value={dropDownValue} onChange={setDropDownValue} />
        </View>
    </View>
}

export function setModerationSessionData({ datastore, sessionData }) {
    datastore.setSessionData(["moderationSession"], sessionData);
}

export function useModerationSessionData() {
    const sessionData = useSessionData(["moderationSession"]);
    return sessionData;
}

export function updateModerationSessionData({ datastore, sessionData }) {
    const oldSessionData = datastore.getSessionData(["moderationSession"])
    datastore.setSessionData(["moderationSession"], { ...oldSessionData, ...sessionData });
}