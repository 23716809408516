import { Narrow } from "np-platform-client/component/basics";
import { DemoHeader, DemoSection } from "np-platform-client/system/demo";
import { ToolTip } from "../../component/zdf/tooltip";
import { CTAButton, Tag } from "np-platform-client/component/button";

export function ToolTipDemo() {
    return (
        <Narrow>
            <DemoSection>
                <DemoHeader label={"Plain Tooltip"} />
                <ToolTip label={"Tooltip"}>
                    <Tag text={"Hover over me"} />
                </ToolTip>
            </DemoSection>
            <DemoSection>
                <DemoHeader label={"Custom offset value"} />
                <ToolTip label={"Tooltip"} offset={50}>
                    <Tag text={"Hover over me"} />
                </ToolTip>
            </DemoSection>
            <DemoSection>
                <DemoHeader label={"Custom Tooltip"} />
                <ToolTip toolTipWidget={<CTAButton label={"Custom Tooltip with a button"}/>}>
                    <Tag text={"Hover over me"} />
                </ToolTip>
            </DemoSection>
        </Narrow>
    )
}