import { HelpBubble } from "np-platform-client/component/help";
import { useInstanceParams } from "np-platform-client/util/params";
import { useEffect } from "react";

export const SingleResponseHelpFeature = {
    name: 'Single Response Help',
    key: 'single_response_help',
    config: {
        composerTopWidgets: [EntryUrlRecorder],
        myAnswerBottomWidgets: [HelpIfDifferentUrl],
    }
}

function EntryUrlRecorder({comment, setComment}) {
    const {entryUrl} = useInstanceParams();
    useEffect(() => {
        setComment({ ...comment, entryUrl });
    }, []);
    return null;
}

function HelpIfDifferentUrl({comment}) {
    const {entryUrl} = useInstanceParams();
    if (!comment.entryUrl || comment.entryUrl === entryUrl) return null;
    return <HelpBubble 
        padTop={12}
        titleLabel="You've been here before" 
        label='Everyone gets one response to each question - you can always update it.' 
        helpKey='single-response' condition={true} left pointer
    />
}
