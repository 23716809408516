import { HorizBox, LoadingScreen, Pad, PadBox } from "np-platform-client/component/basics";
import { CTAButton } from "np-platform-client/component/button";
import { colorGreyBorder, colorGreyPopupBackground, colorTextGrey, colorWhite } from "np-platform-client/component/color";
import { ProfilePhoto } from "np-platform-client/component/people";
import { Heading, UtilityText } from "np-platform-client/component/text";
import { View } from "react-native";
import { ModerationCommentOverview, ModerationFilters } from "../modcommentoverview";
import { useDatastore, useModulePublicData } from "np-platform-client/util/datastore";
import { useEffect, useState } from "react";

export function ModerationDashboardUserProfile({ user }) {
    const [userProfileData, setUserProfileData] = useState({});
    const filteredQueueCounts = useModulePublicData("moderation", ["count_queue_filtered"]);
    const datastore = useDatastore();

    // Fetches user profile data whenever new mod tasks come in or when mod tasks change status (e.g. from awaiting decision to rejected). This way the counters on the user profile will be updated in realtime.
    useEffect(() => {
        const fetchUserProfileData = async () => {
            if (!user || !user.key) {
                return;
            }

            const userProfileDataResult = await datastore.callServerAsync("moderationZdf", "getUserProfileData", { key: user.key });

            // We need to stringify userProfileData and userProfileDataResult to compare them because they are non-primitive data types. A regular comparison will always say they're different even when they have the same value.
            if (userProfileDataResult && JSON.stringify(userProfileDataResult) !== JSON.stringify(userProfileData)) {
                setUserProfileData(userProfileDataResult);
            }
        };

        fetchUserProfileData();
    }, [filteredQueueCounts]);

    const numberOfCounts = 5;

    if (!user) {
        return <LoadingScreen />
    }

    return (
        <View style={{ gap: 24, paddingTop: 58, backgroundColor: colorGreyPopupBackground, flexGrow: 1 }}>
            <Heading label={"User Profile"} level={1} />
            <View
                style={{
                    backgroundColor: colorWhite,
                    paddingVertical: 24,
                    paddingHorizontal: 20,
                    borderRadius: 4,
                    gap: 24,
                }}
            >
                <HorizBox center>
                    <ProfilePhoto type="huge" photo={user.photoUrl} />
                    <Pad size={16} />
                    <View style={{ gap: 2 }}>
                        <Heading text={user.name} />
                        <HorizBox>
                            <UtilityText color={colorTextGrey} label={"Member since "} />
                            <UtilityText
                                color={colorTextGrey}
                                text={new Date(user.firstLogin).getFullYear().toString()}
                            />
                        </HorizBox>
                    </View>
                </HorizBox>
                <PadBox horiz={24}>
                    <View style={{ display: "flex", justifyContent: "space-evenly", flexDirection: "row" }}>
                        <View style={{ display: "flex", flexDirection: "row", width: "calc(100% / " + numberOfCounts + ")" }}>
                            <CountBox count={userProfileData.commentCount|| 0} label={"comments & replies"} />
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", width: "calc(100% / " + numberOfCounts + ")" }}>
                            <CountBoxSeparator />
                            <CountBox count={userProfileData.flagsGivenCount || 0} label={"reported comments"} />
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", width: "calc(100% / " + numberOfCounts + ")" }}>
                            <CountBoxSeparator />
                            <CountBox count={userProfileData.flagsReceivedCount || 0} label={"flagged comments"} />
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", width: "calc(100% / " + numberOfCounts + ")" }}>
                            <CountBoxSeparator />
                            <CountBox count={userProfileData.rejectedCommentCount || 0} label={"rejected comments"} />
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", width: "calc(100% / " + numberOfCounts + ")" }}>
                            <CountBoxSeparator />
                            <CountBox count={40} label={"user warnings"} />
                        </View>
                    </View>
                </PadBox>
                <View style={{ borderTopWidth: 1, borderColor: colorGreyBorder, paddingTop: 16, gap: 16 }}>
                    <Heading label={"User settings"} />
                    <View>
                        <View style={{ width: 234 }}>
                            {/** TODO: Add block functionality */}
                            <CTAButton label={"Block user"} wide disabled={!(user.warnings >= 2)} />
                        </View>
                        <Pad size={16} />
                        <UtilityText type="small" text={"A user must be warned 2 times before they can be blocked"} />
                    </View>
                </View>
            </View>
            <View>
                <Pad size={24} />
                <Heading label={"All comments"} />
                <ModerationCommentOverview
                    structureKey={"profile"}
                    instanceKey={user.key}
                    additionalFilters={[ModerationFilters.Warned]}
                    isOnUserProfile={true}
                />
            </View>
        </View>
    );

}

function CountBox({ count, label }) {
    return (
        <View style={{ gap: 8 }}>
            <Heading level={1} text={count.toString()} />
            <UtilityText color={colorTextGrey} label={label} />
        </View>
    );
}

function CountBoxSeparator() {
    return <View style={{ borderLeftWidth: 1, borderColor: colorGreyBorder, alignSelf: "stretch", paddingRight: 8 }}/>;
}