import { DemoSection } from "np-platform-client/component/demo";
import { ModerationMainMenu } from "../../../structure/zdf/moddashboard";
import { StructureDemo } from "np-platform-client/util/instance";

export function ModerationMainMenuDemo() {
    return (
        <DemoSection label="Moderation Main Menu">
            <ModerationMainMenu/>
        </DemoSection>
    );
}

export function ModDashboardFullDemo() {

    const globals = { initialized: true };

    // TODO: How can we supply demo data that would normally be in module-public?
    const modulePublic = {
        queue: exampleModTasks
    }

    const collections = {
        comment: exampleComments
    }

    const serverCalls = {
        moderationZdf: {
            getRemoteObjectFromDatabase: getRemoteObjectFromDatabaseDemo,
        },
    }

    return (
        <StructureDemo
            roles={['Moderator']}
            globals={globals}
            collections={collections}
            modulePublic={modulePublic}
            serverCall={serverCalls}
            structureKey="moddashboard"
            instanceKey='a'
            features={null}
        />
    );
}

const getRemoteObjectFromDatabaseDemo = ({ objectKey }) => {
    exampleComments.forEach(comment => {
        if (comment.key === objectKey) {
            return comment;
        }
    });
}

const exampleComments = [
    // Awaiting decision
    {
        brokenRuleDescription: "**Do not imply that someone has bad intent**. Assume they have good intentions and critique their ideas and their actions instead.",
        from: "a",
        inReview: true,
        key: "comment1",
        rulesBroken: "[3,4,6]",
        text: "Anyone who says no is a liar",
        time: 1727349552957,
        violates: true,
        violatingText: "Anyone who says no is a liar",
    },
    // Rejected
    {
        brokenRuleDescription: "**Do not imply that someone has bad intent**. Assume they have good intentions and critique their ideas and their actions instead.",
        from: "b",
        inReview: false,
        key: "comment2",
        rulesBroken: "[3,4]",
        text: "Some people really shouldn't be allowed to post on the internet",
        time: 1727428028736,
        violates: true,
        violatingText: "Some people really shouldn't be allowed to post on the internet",
    },
    // Automatically approved
    {
        from: "c",
        inReview: false,
        key: "comment3",
        rulesBroken: "[]",
        text: "Sarah is such a strong and inspiring woman. Even those that do not have a chronic illness can learn from her perseverance.",
        time: 1727428335610,
        violates: false,
    },
    // Human approved - Note that this case is currently indistinguishable from "automatically approved". The only different is that there is also a mod task saved for this comment.
    {
        from: "d",
        inReview: false,
        key: "comment4",
        text: "I can't stand crypto scammers. The whole crypto space is filled with people that care about nothing except money. No empathy, no morals, only greed.",
        time: 1727429099442,
        violates: false,
    }
];

const exampleModTasks = [
    {
        appealText: "Does anyone even read these?",
        instanceKey: "do-we-need-more-regulations-in-the-crypto-space",
        key: "comment1",
        rulesBroken: "[3,4,6]",
        source: "premoderation",
        structureKey: "question",
        text: "Anyone who says no is a liar",
        time: 1727427983691,
        type: "comment",
    },
    {
        appealText: "Censorship is justified in some cases",
        instanceKey: "how-important-is-healthcare-to-you",
        judgement: "reject",
        key: "comment2",
        rulesBroken: "[3,4]",
        source: "premoderation",
        structureKey: "question",
        text: "Some people really shouldn't be allowed to post on the internet",
        time: 1727428028776,
        type: "comment",
    },
    {
        appealText: "Crypto scammers do not have good intentions. That's just how it is. Their intention is to steal from others.",
        instanceKey: "do-we-need-more-regulations-in-the-crypto-space",
        judgement: "approve",
        key: "comment4",
        rulesBroken: "[3,4,6]",
        source: "premoderation",
        structureKey: "question",
        text: "I can't stand crypto scammers. The whole crypto space is filled with people that care about nothing except money. No empathy, no morals, only greed.",
        time: 1727429099442,
        type: "comment",
    }
];

