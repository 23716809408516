import { ChevronLeft, FaceDissatisfied, FaceSatisfied } from "@carbon/icons-react";
import { Pad } from "np-platform-client/component/basics";
import { CTAButton, SubtleButton } from "np-platform-client/component/button";
import { colorBlack, colorGreen, colorRed, colorWhite } from "np-platform-client/component/color";
import { Checkbox } from "np-platform-client/component/form";
import { UtilityText } from "np-platform-client/component/text";
import { useDatastore, useModulePublicData, usePersonaKey } from "np-platform-client/util/datastore";
import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "react-native-web";
import { GUIDELINES } from "../../feature/question/CommunityGuidelinesFeature";
import { ToolTip } from "./tooltip";


async function setJudgement({ judgement, datastore, modTask, reasoning, idx }) {
    await datastore.callServerAsync('moderation', 'setJudgement', {
        key: modTask.key, judgement, reasoning: reasoning || modTask.reasoning
    });
}



export function JudgementCard({ modTask, idx, taskWatchState }) {
    const datastore = useDatastore()
    const personaKey = usePersonaKey()

    const amIviewing = taskWatchState?.modID === personaKey
    const [pageIndex, setPageIndex] = useState(0);

    const s = StyleSheet.create({
        elevatedCard: {
            backgroundColor: !taskWatchState || amIviewing ? colorWhite : "#F7F7F7",
            width: "fit-content",
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            borderRadius: 8,
            padding: 16,
            shadowColor: "#0000001A",
            shadowOffset: { width: 0, height: 4 },
            shadowRadius: 20,
            borderColor: colorBlack,
            borderWidth: 1,
            gap: 16
        }
    });
    return (
        <ToolTip label={"Only one moderator at a time can work on a comment"} disabled={!taskWatchState || amIviewing}>
            <View style={s.elevatedCard}>
                {pageIndex === 0 && (
                    <>
                        {(modTask.judgement === "reject" || !modTask.judgement) && <CTAButton
                            icon={<FaceSatisfied style={{ fill: colorGreen }} />}
                            label="Approve"
                            type="secondary"
                            onPress={() => {
                                setJudgement({ judgement: "approve", datastore, modTask, reasoning: "", idx });
                            }}
                            disabled={taskWatchState && !amIviewing}
                            wide
                        />}
                        {(modTask.judgement === "approve" || !modTask.judgement) && <CTAButton
                            icon={<FaceDissatisfied style={{ fill: colorRed }} />}
                            label="Reject"
                            type="secondary"
                            onPress={() => setPageIndex(1)}
                            disabled={taskWatchState && !amIviewing}
                            wide
                        />}
                    </>
                )}
                {pageIndex === 1 && <RejectionScreen onBack={() => setPageIndex(0)} modTask={modTask} idx={idx} />}
            </View>
        </ToolTip>
    );
}

function RejectionScreen({ onBack, modTask, idx }) {
    const datastore = useDatastore();

    const guidelineNames = [...Object.values(GUIDELINES), 'Other'];

    const defaultCheckboxStates = {};
    guidelineNames.forEach((_, index) => defaultCheckboxStates[index] = false);
    const [selected,setSelected] = useState([])
    const [reasoning, setReasoning] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState(defaultCheckboxStates);

    const s = StyleSheet.create({
        guidelineColumn: {
            margin: 4,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "space-evenly"
        }
    });

    function onChangeSelection({ isSelected, index,guidelineName }) {
        if (isSelected && !selected.includes(index)) {
            setReasoning([...reasoning,guidelineName]);
            setSelected([...selected,index])
        } else if (!isSelected && selected.includes(index)) {
            const cleanedUpSelection = selected.filter((element) => element != index);
            const cleanedUpReasoning = reasoning.filter((element) => element != guidelineName)
            setSelected(cleanedUpSelection);
            setReasoning(cleanedUpReasoning)
        }
        checkboxStates[index] = isSelected;
        setCheckboxStates(checkboxStates);
    }

    async function onReject() {
        await setJudgement({ judgement: "reject", datastore, modTask, reasoning: JSON.stringify(reasoning), idx });
        onBack();
    }

    return (
        <View style={s.guidelineColumn}>
            <SubtleButton icon={ChevronLeft} label={"Back to selection"} onPress={onBack} />
            <Pad size={16} />
            <UtilityText label={"Which community guideline does this comment violate?"} strong />
            <Pad size={16} />
            {guidelineNames.map((guidelineName, index) => (
                <Checkbox
                    label={guidelineName}
                    key={index}
                    value={checkboxStates[index]}
                    onChange={(isSelected) => {
                        onChangeSelection({ isSelected, index,guidelineName });
                    }}
                />
            ))}
            <Pad size={16} />
            <CTAButton
                wide
                label={"Reject"}
                onPress={async () => {
                    onReject();
                }}
            />
        </View>
    );
}