// This feature is enabled when a question is embedded inside another
// instance like a topic

// HACK: This isn't a good way to have questions in topics long term.
// If the questions are big, this could make the topic very heavy and slow
// to load. Longer term we should be reflecting the top 2 questions into 
// the topic and then have a link to view all questions.
export const EmbeddedFeature = {
    key: 'embedded',
    name: 'Embedded',
    config: {
        headerRoundedTopCorners: true,
        commentLimit: () => 2,
        pageBottomWidgets: [NullBottomWidget],
        pageBottomPad: 0,
    }
}

function NullBottomWidget() {
    return null;
}