import { colorBlack, colorLightBlueBackground } from "np-platform-client/component/color"
import { useCollection, useDatastore } from "np-platform-client/util/datastore"
import { useInstanceParams } from "np-platform-client/util/params"
import { useEffect } from "react"
import { scrollTo } from "../../../platform-specific/navigation.web"
import { View } from "react-native-web"
import { Heading, UtilityText } from "np-platform-client/component/text"

export const JumpToCommentFeature = {
    name: "Jump To Comment",
    key: "jumptocomment",
    config: {
        pageTopWidgets: [ExpandRepliesWidget],
        replyAboveWidgets: [ScrollToComment],
        commentAboveWidgets: [ScrollToComment],
        commentStylers: [CommentHighlight],
    }
}

function CommentHighlight({ comment }) {
    const commentID = useInstanceParams().commentID;

    if (commentID !== null && commentID !== undefined && commentID === comment.key) {
        const isReply = comment.replyTo !== null && comment.replyTo !== undefined;

        return {
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)",
            borderColor: colorBlack,
            borderWidth: 1,
            borderRadius: 8,
            paddingHorizontal: isReply ? 16 : 0
        };
    }
}

function ScrollToComment({ comment }) {
    const commentID = useInstanceParams().commentID
    useEffect(() => {
        if (commentID)
            scrollTo({ elementID: commentID, behavior: "smooth", block: "center" });
    })
}

function ExpandRepliesWidget() {
    const datastore = useDatastore()
    const commentID = useInstanceParams().commentID;
    const comments = useCollection("comment")

    useEffect(() => {
        if (commentID !== null && commentID !== undefined) {
            const currentComment = comments.find((c) => c.key === commentID)
            const isParent = !Object.keys(currentComment).includes("replyTo")
            if (!isParent) {
                const parentComment = findParent(currentComment.replyTo,comments)
                datastore.setSessionData(['showReplies', parentComment.key], true);
            }
        }

    }, [])

    function findParent(replyTo,comments) {
        const comment = comments.find((c) => c.key === replyTo)
        if (Object.keys(comment).includes("replyTo")) {
            findParent(replyTo)
        }
        return comment
    }
    return null
}
