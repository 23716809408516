import { colorWhite } from "np-platform-client/component/color";
import { Heading, UtilityText } from "np-platform-client/component/text";
import { StyleSheet, View } from "react-native";
import { HorizBox, Pad, PadBox } from "np-platform-client/component/basics";
import { DropDownSelector } from "np-platform-client/component/button";
import { useEffect, useMemo, useRef, useState } from "react";
import { ModerationQueueCommentCard } from "./moderationqueue/moderationqueuecommentcard";
import { useModerationAuthors, useModerationQueue } from "../moderation";
import { useCollection, useDatastore, useModulePublicData, usePersonaKey, useSessionData } from "np-platform-client/util/datastore";
import { Banner } from "np-platform-client/component/banner";
import { gotoInstance } from "np-platform-client/util/navigate";
import { JudgementCard } from "./judgementcard";
import { SpacedArray } from "np-platform-client/system/demo";
import { REPLACE_ZDF_FilterButton } from "./button";
import { gotoUrl } from "np-platform-client/util/url";
import { ModerationInitializer, updateModerationSessionData, useModerationSessionData } from "../../structure/zdf/moddashboard";

export const ModerationFilters = {
    AwaitingDecision: { id: "awaiting_decision", text: "Awaiting decision", emoji: "🔍" },
    AutomaticallyApproved: { id: "auto_approved", text: "Automatically approved", emoji: "✅" },
    HumanApproved: { id: "human_approved", text: "Humanly approved", emoji: "🕵️" },
    Rejected: { id: "rejected", text: "Humanly rejected", emoji: "⛔️" },
    Warned: { id: "warned", text: "Warnings", emoji: "🚦" },
};

export const ModerationFiltersDefault = [
    ModerationFilters.AwaitingDecision,
    ModerationFilters.AutomaticallyApproved,
    ModerationFilters.HumanApproved,
    ModerationFilters.Rejected,
]


export function ModerationCommentOverviewAll() {
    return (
        <View style={ModerationCommentOverviewStyle.outerContainer}>
            <Heading level={1} label={"Comments"} />
            <ModerationCommentOverview />
        </View>
    );
}

const ModerationCommentOverviewStyle = StyleSheet.create({
    outerContainer: {
        flex: 1,
        height: "100%",
        paddingVertical: 80,
    },
});

function useQueueCountGlobal() {
    const countAwaiting = useModulePublicData("moderation", ["count_queue_filtered", ModerationFilters.AwaitingDecision.id])
    const countHumanApproved = useModulePublicData("moderation", ["count_queue_filtered", ModerationFilters.HumanApproved.id])
    const countAutomaticallyApproved = useModulePublicData("moderation", ["count_queue_filtered", ModerationFilters.AutomaticallyApproved.id])
    const countRejected = useModulePublicData("moderation", ["count_queue_filtered", ModerationFilters.Rejected.id])
    return {
        [ModerationFilters.AwaitingDecision.id]: countAwaiting ?? 0,
        [ModerationFilters.AutomaticallyApproved.id]: countAutomaticallyApproved ?? 0,
        [ModerationFilters.HumanApproved.id]: countHumanApproved ?? 0,
        [ModerationFilters.Rejected.id]: countRejected ?? 0
    }
}

function useQueueCountInstance() {
    const queueAwaiting = useCollection('queue_filtered_awaiting_decision');
    const queueHumanApproved = useCollection('queue_filtered_human_approved');
    const queueRejected = useCollection('queue_filtered_rejected');
    const queueAutoApproved = useCollection('queue_filtered_auto_approved');

    return {
        [ModerationFilters.AwaitingDecision.id]: queueAwaiting.filter((task => task.time)).length,
        [ModerationFilters.AutomaticallyApproved.id]: queueAutoApproved.filter((task => task.time)).length,
        [ModerationFilters.HumanApproved.id]: queueHumanApproved.filter((task => task.time)).length,
        [ModerationFilters.Rejected.id]: queueRejected.filter((task => task.time)).length
    }
}

function useQueueCounts({ structureKey, instanceKey }) {
    if (structureKey && instanceKey) {
        return useQueueCountInstance()
    } else {
        return useQueueCountGlobal()
    }
}
function getUserQueueCount(userKey) {
    const queue = useUserQueue({ userId: userKey });
    const queueArray = Object.values(queue || {});

    const awaitingDecision = queueArray.filter((v) => v.judgement === undefined);
    const rejected = queueArray.filter((v) => v.judgement === "reject");
    const humanlyApproved = queueArray.filter((v) => v.judgement === "approve" && v.approvedBy === "human");
    const automaticallyApproved = queueArray.filter(
        (v) => v.judgement === "approve" && v.approvedBy === "ai-premoderation"
    );

    return {
        [ModerationFilters.AwaitingDecision.id]: awaitingDecision.length,
        [ModerationFilters.AutomaticallyApproved.id]: automaticallyApproved.length,
        [ModerationFilters.HumanApproved.id]: humanlyApproved.length,
        [ModerationFilters.Rejected.id]: rejected.length,
    };
}

export function useUserQueue({ userId }) {
    const datastore = useDatastore();
    const filteredQueueCounts = useModulePublicData("moderation", ["count_queue_filtered"]);
    const [userQueue, setUserQueue] = useState({});

    async function fetchUserQueue() {
        const result = await datastore.callServerAsync("moderationZdf", "getUserQueue", { key: userId });
        setUserQueue(result);
    }

    // Fetches queue whenever new mod tasks come in or when mod tasks change status (e.g. from awaiting decision to rejected)
    useEffect(() => {
        fetchUserQueue();
    }, [filteredQueueCounts]);

    return userQueue;
}


/**
 * General hook to retrieve all necessary queue data
 * @param filterOptions Array of `ModerationState`'s
 * @param sortingOrder "newest" or "oldest"
 * @param queueRetrivalMethod Hook to retrieve a subqueue based on filter option (currently all or instance based)
 */

// TODO:
// - It retrieves everything and filters it clientside, it should be limited already in the server
// - Pagination
// - Do not update the returned list live, only on demand ("new items, load more?") to prevent irritating behavior for moderators

function useQueueData({ filterOptions = [], sortingOrder = "oldest", queueRetrievalMethod = useFilteredQueueAll }) {

    // Get all subQueues for each ModerationState
    const awaitingQueue = queueRetrievalMethod(ModerationFilters.AwaitingDecision);
    const automaticallyApprovedQueue = queueRetrievalMethod(ModerationFilters.AutomaticallyApproved);
    const humanApprovedQueue = queueRetrievalMethod(ModerationFilters.HumanApproved);
    const rejectedQueue = queueRetrievalMethod(ModerationFilters.Rejected);

    return useMemo(() => {
        let combinedQueue = [];

        // Assemble queues based on the filterOptions array
        if (filterOptions.includes(ModerationFilters.AwaitingDecision)) {
            combinedQueue = combinedQueue.concat(awaitingQueue);
        }
        if (filterOptions.includes(ModerationFilters.AutomaticallyApproved)) {
            combinedQueue = combinedQueue.concat(automaticallyApprovedQueue);
        }
        if (filterOptions.includes(ModerationFilters.HumanApproved)) {
            combinedQueue = combinedQueue.concat(humanApprovedQueue);
        }
        if (filterOptions.includes(ModerationFilters.Rejected)) {
            combinedQueue = combinedQueue.concat(rejectedQueue);
        }

        // No filter selected = show comments from all sub-queues
        // TODO: Reconsider if this is necessary. Showing everything at once might cause performance issues.
        if (filterOptions.length === 0) {
            combinedQueue = combinedQueue.concat(awaitingQueue, automaticallyApprovedQueue, humanApprovedQueue, rejectedQueue);
        }

        // Sort by time based on the sortingOrder parameter
        const returnQueue = combinedQueue.sort((a, b) => {
            if (sortingOrder === "newest") {
                return b.time - a.time;
            } else {
                return a.time - b.time;
            }
        });

        // Return assembled queue and all queue counts
        return {
            queue: returnQueue
        }

    }, [filterOptions, awaitingQueue, automaticallyApprovedQueue, humanApprovedQueue, rejectedQueue, sortingOrder]);
};

/** Retrieves modTasks for all instances (from module public) */
export function useFilteredQueueAll(filterOption) {
    let modObjs
    if (filterOption === ModerationFilters.AwaitingDecision) {
        modObjs = useModulePublicData('moderation', ['queue_filtered', "awaiting_decision"]);
    } else if (filterOption === ModerationFilters.HumanApproved) {
        modObjs = useModulePublicData('moderation', ['queue_filtered', "human_approved"]);
    } else if (filterOption === ModerationFilters.Rejected) {
        modObjs = useModulePublicData('moderation', ['queue_filtered', "rejected"]);
    } else if (filterOption === ModerationFilters.AutomaticallyApproved) {
        modObjs = useModulePublicData('moderation', ['queue_filtered', "auto_approved"]);
    }

    // Use useMemo to prevent unnecessary recalculations if modObjs hasn't changed
    return useMemo(() => {
        const keys = Object.keys(modObjs || {});
        return keys.map(key => ({ key, ...modObjs[key] }))
            .sort((a, b) => b.time - a.time);
    }, [modObjs]);
}

/** Retrieves modTasks only for current instance */
export function useFilteredQueueInstance(filterOption) {
    let modObjs = []
    if (filterOption === ModerationFilters.AwaitingDecision) {
        modObjs = useCollection('queue_filtered_awaiting_decision');
    } else if (filterOption === ModerationFilters.HumanApproved) {
        modObjs = useCollection('queue_filtered_human_approved');
    } else if (filterOption === ModerationFilters.Rejected) {
        modObjs = useCollection('queue_filtered_rejected');
    } else if (filterOption === ModerationFilters.AutomaticallyApproved) {
        modObjs = useCollection('queue_filtered_auto_approved');
    }

    // Use useMemo to prevent unnecessary recalculations if modObjs hasn't changed
    return useMemo(() => {
        // In collections deleted objects are not removed
        // We have to filter them
        return modObjs.filter(modTask => (modTask != null && modTask.type))
    }, [modObjs]);
}

export function useFilteredQueueUser(filterOption, instanceKey) {
    let modObjs = [];
    const queue = useUserQueue({ userId: instanceKey });
    const queueArray = Object.values(queue || {});

    if (filterOption === ModerationFilters.AwaitingDecision) {
        modObjs = queueArray.filter((v) => v.judgement === undefined);
    } else if (filterOption === ModerationFilters.HumanApproved) {
        modObjs = queueArray.filter((v) => v.judgement === "approve" && v.approvedBy === "human");
    } else if (filterOption === ModerationFilters.Rejected) {
        modObjs = queueArray.filter((v) => v.judgement === "reject");
    } else if (filterOption === ModerationFilters.AutomaticallyApproved) {
        modObjs = queueArray.filter((v) => v.judgement === "approve" && v.approvedBy === "ai-premoderation");
    }

    // Use useMemo to prevent unnecessary recalculations if modObjs hasn't changed
    return useMemo(() => {
        // In collections deleted objects are not removed
        // We have to filter them
        return modObjs.filter(modTask => (modTask != null && modTask.type))
    }, [modObjs]);
}

export function ModerationCommentOverview({ structureKey, instanceKey, additionalFilters = [], isOnUserProfile = false }) {
    const [selectedFilters, setSelectedFilters] = useState([ModerationFilters.AwaitingDecision]);
    const [sortingOrder, setSortingOrder] = useState("oldest");
    const datastore = useDatastore()

    let { queue } = useQueueData({
        filterOptions: selectedFilters,
        sortingOrder,
        queueRetrievalMethod:
            structureKey && instanceKey
                ? isOnUserProfile
                    ? (filter) => useFilteredQueueUser(filter, instanceKey)
                    : useFilteredQueueInstance
                : useFilteredQueueAll,
    });

    let queueCounts = 0
    if (isOnUserProfile) {
        queueCounts = getUserQueueCount(instanceKey)
    } else {
        queueCounts = useQueueCounts({ structureKey, instanceKey })
    }

    function toggleFilter({ filterOption }) {
        // Remove filter from list
        if (selectedFilters.includes(filterOption)) {
            setSelectedFilters(selectedFilters.filter(selectedFilter => selectedFilter !== filterOption));
        }
        // Add filter to list
        else {
            setSelectedFilters([...selectedFilters, filterOption]);
        }
    }

    useEffect(() => {
        // Change selection when filters or sorting order changes
        datastore.setSessionData(["modTask-selected-idx"], null);
    }, [selectedFilters, sortingOrder])

    return (
        <View>
            <Pad size={26} />
            <SpacedArray pad={8} horiz>
                {
                    ModerationFiltersDefault.concat(additionalFilters).map(filter => <REPLACE_ZDF_FilterButton
                        key={filter.text}
                        label={filter.text}
                        emoji={filter.emoji}
                        selected={selectedFilters.includes(filter)}
                        count={queueCounts[filter.id] <= 100 ? queueCounts[filter.id].toString() : "100+"}
                        onPress={() => toggleFilter({ filterOption: filter })}
                    />)
                }
            </SpacedArray>
            <ModerationCommentList queue={queue} sortingOrder={sortingOrder} onChangeSortingOrder={setSortingOrder} />
        </View>
    );
}

export function ModerationCommentList({ queue = [], sortingOrder, onChangeSortingOrder }) {
    const datastore = useDatastore();
    const modAuthors = useModerationAuthors();

    return (
        <PadBox top={32}>
            <DropDownSelector
                label="Sort by"
                value={sortingOrder}
                onChange={onChangeSortingOrder}
                options={[
                    { key: "oldest", label: "Oldest" },
                    { key: "newest", label: "Newest" },
                ]}
            />

            {queue.length > 0 ? (
                <>
                    <ModerationInitializer />
                    {queue.map((modTask, idx) => (
                        <ModTask
                            key={"modTask" + modTask.key + modTask.judgement}
                            idx={idx}
                            modTaskKey={modTask.key}
                            modAuthor={modAuthors?.[modTask.key]}
                        />
                    ))}
                </>
            ) : (
                <NoCommentsBanner />
            )}
        </PadBox>
    );
}

function useTaskWatchState({modTask, selected}) {
    const datastore = useDatastore()
    const personaKey = usePersonaKey();
    let taskWatchState = useModulePublicData("moderation", ["taskWatchState",modTask.key])
    const taskWatchStateRef = useRef(taskWatchState)
    // Check if Task Watch state is expired
    // Failsafe in case a taskWatchState is not properly cleaned up (e.g. direct tab close)
    const taskWatchStateValid = ( taskWatchState && (Date.now() - taskWatchState.startTime) < 600000 )
    const taskWatchStateHeartBeatRef = useRef(null)

    async function deleteFromReview() {
        if (taskWatchStateRef.current !== null && taskWatchStateRef.current !== undefined) {
            await datastore.callServerAsync("moderationZdf", "unwatchTask", { modTaskID: modTask.key, modID: personaKey })
        }
        clearTimeout(taskWatchStateHeartBeatRef.current)
    }
    async function takeIntoReview() {
        if(!taskWatchStateRef.current || !taskWatchStateValid || taskWatchStateRef.current?.modID === personaKey) {
            await datastore.callServerAsync("moderationZdf", "watchTask", { modTaskID: modTask.key, modID: personaKey })
        }
    }

    useEffect(()=>{
        taskWatchStateRef.current = taskWatchState
        if(!taskWatchStateValid && selected) {
            takeIntoReview();
        }

        // If you hold the task watch state,  assign it again 5 minutes later, to not let it expire
        if(taskWatchStateValid && taskWatchState.modID === personaKey && selected) {
            taskWatchStateHeartBeatRef.current = setTimeout(()=>{
                takeIntoReview();
            },[300000])
        }
        // You do not select the card anymore, you have to unlock the task watch state
        if(!selected && taskWatchStateValid && taskWatchState.modID === personaKey) {
            deleteFromReview();
        }
    }, [selected, taskWatchState])

    // Release the watchstate on unmount
    useEffect(() => {
        return () => {
            if (taskWatchStateRef.current && taskWatchStateRef.current.modID === personaKey) {
                deleteFromReview()
            }
        }
    }, [])

    return taskWatchStateValid ? taskWatchState : null
}

export function ModerationCommentListItem({ comment, modTask, modAuthor, idx }) {
    const selectedIdx = useSessionData(['modTask-selected-idx']);
    const moderationSessionData = useModerationSessionData();
    const threadIsOpen = moderationSessionData?.pageId === "page-threads"
    const isSelected = selectedIdx === modTask.key;
    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    
    // TODO: This causes way too many server calls
    // const taskWatchState = useTaskWatchState({ modTask: modTask, selected: isSelected })
    const taskWatchState = null;

    const onPressView = () => {
        if (threadIsOpen) {
            gotoInstance({ structureKey: modTask.structureKey, instanceKey: modTask.instanceKey, params: { "commentID": modTask.key } });
        } else {
            let url = new URL(window.location);
            let params = url.searchParams;
            params.delete("tab")
            params.set("tab", "page-threads")
            params.set("thread", modTask.instanceKey);
            gotoUrl(url.toString());
            updateModerationSessionData({ datastore, sessionData: { openedThread: modTask.instanceKey } });
        }
    }

    const onClickCard = () => {
        if (isSelected) {
            datastore.setSessionData(["modTask-selected-idx"], null)
        } else {
            datastore.setSessionData(['modTask-selected-idx'], modTask.key);
        }
    }

    const onExpandCard = () => {
        datastore.setSessionData(['modTask-selected-idx'], modTask.key);
    }

    const onCollapseCard = () => {
        datastore.setSessionData(["modTask-selected-idx"], null)
    }

    return (
        <View>
            <Pad size={16} />
            <HorizBox>
                <ModerationQueueCommentCard
                    comment={comment}
                    modAuthor={modAuthor}
                    modTask={modTask}
                    highlighted={isSelected}
                    onPressView={onPressView}
                    onClickCard={onClickCard}
                    onExpand={onExpandCard}
                    onCollapse={(onCollapseCard)}
                    viewLabel={threadIsOpen ? "View" : "View in thread"}
                    taskWatchState={taskWatchState}
                />
                {/* Judgement card reserves a fixed amout of space to not make the comment card change in width */}
                <View style={{ width: 300 }}>
                    {isSelected && (
                        <HorizBox>
                            <Pad size={16} />
                            <JudgementCard modTask={modTask} taskWatchState={taskWatchState}/>
                        </HorizBox>
                    )}
                </View>
            </HorizBox>
        </View>
    );
}

export function NoCommentsBanner() {
    return (
        <View style={{ width: "fit-content" }}>
            <Pad size={16} />
            <Banner color={colorWhite}>
                <UtilityText label={"No comments."} />
            </Banner>
        </View>
    );
}

function ModTask({ modTaskKey = "", modAuthor, idx }) {
    const datastore = useDatastore();
    const [modTaskSourceObject, setModTaskSourceObject] = useState();

    const modTask = useModulePublicData('moderation', ['queue', modTaskKey]);

    // Keeping this generic because mod tasks might not always be based on comments
    useEffect(() => {
        if (!modTask) {
            return
        }
        async function fetchSourceObject() {
            const object = await datastore.callServerAsync("moderationZdf", "getRemoteObjectFromDatabase", {
                collectionType: modTask.type,
                objectKey: modTask.key,
                instanceKey: modTask.instanceKey,
                structureKey: modTask.structureKey,
            });
            setModTaskSourceObject(object);
        }
        fetchSourceObject();
    }, [modTask]);

    return (
        <View>
            {modTask?.type === "comment" && modTaskSourceObject && (
                <ModerationCommentListItem comment={modTaskSourceObject} modTask={modTask} modAuthor={modAuthor} idx={idx} />
            )}
        </View>
    );
}