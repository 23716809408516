import { HorizBox, Pad, PadBox } from "np-platform-client/component/basics";
import { PopupPanel, TextButton } from "np-platform-client/component/button";
import { UtilityText } from "np-platform-client/component/text";
import { useState } from "react";
import { View } from "react-native-web";

export function REPLACE_ZDF_DropDownSelector({label, options, value ,onChange=()=>{}}) {
    const [hover, setHover] = useState(false);
    const selectedOption = options.find(o => o.key == value) || options[0];
    function popupContent({onClose}) {
        return <View >
            {options.map((o, i) => <View key={i}>
                {i != 0 && <Pad size={20} />}
                <HorizBox center>
                {o.optionLeftItem}
                <PadBox horiz={4} />
                <TextButton label={o.label} type='tiny' onPress={() => {onChange(o.key); onClose()}} />
                </HorizBox>
            </View>)}
        </View>
    }
    ///TODO: Remove. For Review: Idk if we should add the left and right child to the options card as well or not, i didnt do it for now
    return <PopupPanel popupContent={popupContent} alignRight setHover={setHover}>
        <HorizBox center>
            <UtilityText label={label} type='tiny' strong />
            {label && <UtilityText text=': ' type='tiny' strong />}
            <PadBox horiz={4} />
            {selectedOption.optionLeftItem?? selectedOption.optionLeftItem}
            {selectedOption.optionLeftItem && <PadBox horiz={4} />}
            <UtilityText label={selectedOption.label} type='tiny' underline={hover} strong />
            <PadBox horiz={4} />
            {selectedOption.optionRightItem?? selectedOption.optionRightItem}
        </HorizBox>
    </PopupPanel>
}