import { ArrowLeft, ArrowRight, Chat, ChevronLeft, IdManagement, Link, Time } from "@carbon/icons-react";
import { Banner } from "np-platform-client/component/banner";
import { HorizBox, HoverView, LoadingScreen, Pad, PadBox } from "np-platform-client/component/basics";
import { CTAButton, DropDownSelector, Tag, TextButton } from "np-platform-client/component/button";
import { colorGreyPopupBackground, colorLightGreen, colorRedBackground, colorTextBlue, colorTextGrey, colorWhite } from "np-platform-client/component/color";
import { Heading, LinkText, Paragraph, UtilityText } from "np-platform-client/component/text";
import { Datastore, useCollection, useDatastore, useGlobalProperty, useInstanceContext, useInstanceKey, useIsLive, useModulePublicData, useSiloKey } from "np-platform-client/util/datastore";
import { useEffect, useMemo, useState } from "react";
import { Image } from "react-native";
import { StyleSheet, View } from "react-native";
import { useModerationAuthorById, useModerationAuthors, useModerationAuthorsByIds } from "../../moderation";
import { setModerationSessionData, updateModerationSessionData, useModerationSessionData } from "../../../structure/zdf/moddashboard";
import { ScrollView } from "react-native-web";
import { TabBar, TabContent, TabNavigation } from "../../../contrib/zdf/tabnavigation";
import { REPLACE_ZDF_DropDownSelector } from "../dropdownselector";
import { gotoUrl } from "np-platform-client/util/url";
import { getScreenStackForUrl } from "np-platform-client/util/navigate";
import { ModerationCommentOverview } from "../modcommentoverview";
import { keyToUrl } from "../../../util/util";
import { REPLACE_ZDF_LinkText } from "../text";
import { EmbeddedInstance } from "np-platform-client/util/instance";
import { ParentDatastoreProvider } from "../parentDatastore";
import { Modal } from "np-platform-client/component/modal";
import { SpacedArray } from "np-platform-client/system/demo";

const useModerationThreads = ({ timestamp = Date.now(), count = 1, sortOrder = "oldest" }) => {

    const questionThreads = useModulePublicData("question")
    const [filteredThreads, setFilteredThreads] = useState(null)
    const [remainingThreadCount, setRemainingThreadCount] = useState(0)
    const [newThreadCount, setNewThreadCount] = useState(0)

    useEffect(() => {

        if (questionThreads) {
            // Convert the question threads into a list of objects with their keys
            const entriesWithKey = Object.entries(questionThreads)
                .map(([key, value]) => ({ key, ...value }));

            // Count new threads (those newer than the given timestamp)
            const newThreads = entriesWithKey.filter(thread => thread.time > timestamp);
            setNewThreadCount(newThreads.length); // Set the new thread count

            // Filter threads older than or equal to the timestamp
            const filteredThreads = entriesWithKey
                .filter(thread => thread.time <= timestamp)
                .sort((a, b) => {
                    if (sortOrder === "newest") {
                        return b.time - a.time; // Sort newest first
                    } else if (sortOrder === "oldest") {
                        return a.time - b.time; // Sort oldest first
                    }
                    return 0; // Fallback (no sorting)
                })

            setRemainingThreadCount(filteredThreads.length - count)

            setFilteredThreads(filteredThreads.slice(0, count))
        }
    }, [questionThreads, sortOrder, count])

    return {
        allThreads: questionThreads,
        threads: filteredThreads,
        remainingThreadCount: remainingThreadCount,
        newThreadCount: newThreadCount
    }
};

export function ModDashBoardThreadsOverview() {

    const [filter, setFilter] = useState("oldest");
    const [loadAmount, setLoadAmount] = useState(10);
    const [loadTimestamp, setLoadTimestamp] = useState(Date.now());

    const { threads, allThreads, newThreadCount, remainingThreadCount } = useModerationThreads({ timestamp: loadTimestamp, sortOrder: filter, count: loadAmount })

    const authorIds = useMemo(() => {
        return threads?.map(thread => thread.from) || [];
    }, [threads]);
    const authors = useModerationAuthorsByIds(authorIds);

    const modDashboardState = useModerationSessionData();

    const loadMoreThreads = () => {
        setLoadAmount(loadAmount + 10)
    }

    const loadNewThreads = () => {
        setLoadAmount(loadAmount + newThreadCount)
        setLoadTimestamp(Date.now())
    }

    const renderedContent = <>
        {(threads && modDashboardState?.openedThread) && <ParentDatastoreProvider>
            <EmbeddedInstance structureKey={"question"} instanceKey={modDashboardState.openedThread} screenKey={"moderationDetails"}>
                <ModDashboardThreadDetails thread={allThreads[modDashboardState.openedThread]} threadKey={modDashboardState.openedThread} />
            </EmbeddedInstance>
        </ParentDatastoreProvider>
        }
        {(threads && !modDashboardState?.openedThread) &&
            <ModDashBoardThreadsOverviewList
                threads={threads}
                authors={authors}
                filterOption={filter}
                onChangeFilter={setFilter}
                newThreadCount={newThreadCount}
                remainingThreadCount={remainingThreadCount}
                onLoadMore={loadMoreThreads}
                onLoadNew={loadNewThreads}
            />}
        {!threads && <LoadingScreen />}
    </>

    return <View style={{ flexGrow: 1 }}>
        {renderedContent}
        <ThreadInitializer />
    </View>
}

export function ThreadInitializer() {
    const datastore = useDatastore();
    const moderationSessionData = useModerationSessionData();
    const stack = getScreenStackForUrl(window.location.href);

    let url = new URL(window.location);
    let params = url.searchParams;

    useEffect(() => {
        if (!moderationSessionData.openedThread) {
            let threadId = null;
            if (stack.screenStack.length > 0) {
                if (stack.screenStack[0].params.thread) {
                    threadId = stack.screenStack[0].params.thread
                }
            }

            if (threadId) {
                updateModerationSessionData({ datastore, sessionData: { openedThread: threadId } });
            }
        }
    }, [params, moderationSessionData]);
}

function InfiniteScrollView({ children, style, onScrollToEnd, disabled, loadingMore }) {

    //TODO handle edge case: No scrollbar but there are new items to load

    const handleScroll = (event) => {
        if (disabled || loadingMore) return

        const { contentOffset, layoutMeasurement, contentSize } = event.nativeEvent;
        // Check if the user has scrolled to the bottom
        if (layoutMeasurement.height + contentOffset.y >= contentSize.height - 20) { // Add a threshold
            onScrollToEnd && onScrollToEnd();
        }
    };

    return <ScrollView style={style} onScroll={handleScroll} scrollEventThrottle={16}>
        {children}
        {loadingMore && <LoadingScreen />}
    </ScrollView>
}


export function ModDashBoardThreadsOverviewList({ threads,
    authors,
    filterOption,
    onChangeFilter,
    newThreadCount = 0,
    remainingThreadCount = 0,
    onLoadMore,
    onLoadNew
}) {

    const [loadingMore, setLoadingMore] = useState(false)

    const datastore = useDatastore();
    const filterOptions = [
        { key: 'newest', label: 'Newest' },
        { key: 'oldest', label: 'Oldest' },
    ]

    const openThreadDetails = (threadId) => {
        let url = new URL(window.location);
        let params = url.searchParams;
        params.set("thread", threadId);
        gotoUrl(url.toString());

        updateModerationSessionData({ datastore, sessionData: { openedThread: threadId } });
    }

    const loadMoreItems = () => {
        onLoadMore && onLoadMore();
        setLoadingMore(true)
    }
    useEffect(() => {
        setLoadingMore(false)
    }, [remainingThreadCount])

    // TODO Fix layout and add proper scrolling 
    return <View style={{ backgroundColor: colorGreyPopupBackground, flexGrow: 1 }}>
        <InfiniteScrollView style={{ maxHeight: 750 }} disabled={remainingThreadCount <= 0} loadingMore={loadingMore} onScrollToEnd={loadMoreItems}>
            <PadBox top={58}>
                <Heading level={1} label={"Threads"} />
                <Pad size={48} />
                <View style={{ gap: 16 }}>
                    <REPLACE_ZDF_DropDownSelector options={filterOptions} value={filterOption} onChange={onChangeFilter} />
                    {(newThreadCount > 0) && <Banner>
                        <UtilityText label={"There a new threads available"} />
                        <CTAButton label={"Show new threads"} onPress={onLoadNew} />
                    </Banner>}
                    {threads ? <View style={{ gap: 16 }}>
                        { // TODO: Wrapping each ListItem in a nested datastore might be data-heavy. We might need a "lightweight" option with ignoring collections
                            threads.map((thread, i) => (<ModerationDashboardThreadCard
                                author={authors?.[thread?.from]}
                                thread={thread}
                                key={i.toString() + thread.question}
                                onPressCard={() => { openThreadDetails(thread.key) }}
                            />
                            ))
                        }
                    </View> :
                        <LoadingScreen />}
                </View>
            </PadBox>
        </InfiniteScrollView>
    </View>
}

//TODO What is the correct padding and gap
export function ModerationDashboardThreadCard({ thread, secondaryTags = [], author, onPressCard }) {

    const features = useGlobalProperty("features")

    const threadIsClosed = features?.readonly ? true : false

    return <HoverView onPress={onPressCard}>
        <View style={threadCardStyle.cardContainer}>
            <View style={threadCardStyle.tagRow}>
                <Tag compact label={threadIsClosed ? "Closed" : "Open"} color={threadIsClosed ? colorRedBackground : colorLightGreen} />
                {secondaryTags.map((tag, i) => <Tag key={tag} compact label={tag} />)}
            </View>
            <View style={threadCardStyle.mainContentRow}>
                <View style={threadCardStyle.titleBlock}>
                    <Paragraph text={thread.question} strong />
                    <UtilityText type="tiny" color={colorTextGrey} text={author?.name} />
                </View>
            </View>
            <ThreadMetaData thread={thread} threadKey={thread.key} userId={author?.from} />
        </View>
    </HoverView>
}

const threadCardStyle = StyleSheet.create({
    cardContainer: {
        borderRadius: 10,
        padding: 16,
        gap: 16,
        backgroundColor: colorWhite,
        shadowOffset: { width: 0, height: 2 },
        shadowColor: "#0000001A",
        shadowRadius: 10
    },
    tagRow: {
        flexDirection: "row",
        gap: 12
    },
    image: {
        width: 56,
        height: 56
    },
    mainContentRow: {
        flexDirection: "row",
        gap: 12,
        alignItems: "center"
    },
    titleBlock: {
        gap: 4
    },
    detailRow: {
        gap: 16,
        flexDirection: "row"
    },
    detailItem: {
        gap: 4,
        flexDirection: "row"
    }
})


export function ModDashboardThreadDetails() {
    const datastore = useDatastore();
    const question = useGlobalProperty("name");
    const threadKey = useInstanceKey();
    const thread = useModulePublicData("question", [threadKey])
    const threadAuthor = useModerationAuthorById(thread?.from)

    const articles = useCollection("backlink_article")

    if (!thread) {
        return <LoadingScreen />
    }

    return <PadBox top={58}>
        <SpacedArray pad={8}>
            <Heading text={question} level={1} />
            <UtilityText type="tiny" color={colorTextGrey} label={"Author: {author}"} formatParams={{author: threadAuthor?.name}}/>
        </SpacedArray>
        <Pad size={24} />
        <ThreadMetaData thread={thread} threadKey={threadKey} articles={articles} />
        <Pad size={56} />
        <ThreadDetailTabs thread={thread} threadKey={threadKey} />
    </PadBox>

}

function ThreadMetaData({ thread, threadKey, articles }) {
    const commentCount = thread.commentCount
    const [display, shouldDisplay] = useState(false)
    return <View style={threadCardStyle.detailRow}>
        {thread.time && <View style={threadCardStyle.detailItem}>
            <Time />
            <HorizBox>
                <UtilityText type="tiny" text={monthAbbreviations[new Date(thread.time).getMonth()]} color={colorTextGrey} />
                <UtilityText type="tiny" text={" " + new Date(thread.time).getDay()} color={colorTextGrey} />
            </HorizBox>

        </View>}
        {commentCount !== null && <View style={threadCardStyle.detailItem}>
            <Chat />
            <UtilityText type="tiny" text={commentCount?.toString()} color={colorTextGrey} />
        </View>}
        {threadKey && <View style={threadCardStyle.detailItem}>
            <IdManagement />
            <UtilityText type="tiny" text={threadKey} color={colorTextGrey} />
        </View>}
        {articles && <View style={threadCardStyle.detailItem}>
            <Link color="#5A1EF5" />
            <TextButton formatParams={{numArticles:articles.length}} type="tiny" color="#5A1EF5" label={"{numArticles} linked stories"} onPress={() => shouldDisplay(true)} />
            {display && articles.length > 0 && <ArticleModal articles={articles} onClose={() => shouldDisplay(false)} />}
        </View>}
    </View>
}

export function ArticleModal({ onClose, articles }) {
    return <Modal onClose={onClose}>
        <View style={{ paddingVertical: 40, paddingHorizontal: 20, gap: 8 }}>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                <UtilityText type="small" strong label={"Linked stories "} />
                <PadBox horiz={4} />
                <View style={{ width: 25, height: 25, borderRadius: "50%", backgroundColor: "#E9E9E9", justifyContent: "center", alignItems: "center" }}>
                    <UtilityText label={articles.length} center strong />
                </View>
            </View>
            <Pad />
            <View style={{gap:8}}>
                {articles.map((article, key) => <ArticleCardThread key={key} article={article} />)}

            </View>
        </View>
    </Modal>
}

const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
]

function ArticleCardThread({ article = {} }) {
    return <View style={ArticleCardThreadStyle.outer}>
        <View style={{ display: "flex", justifyContent: "flex-start" }}>
            <HorizBox center>
                <Image style={ArticleCardThreadStyle.image} source={article.image} />
                <PadBox horiz={4} />
                <View style={ArticleCardThreadStyle.articleList}>
                    <Paragraph text={article.title} color={colorTextGrey} />
                    <REPLACE_ZDF_LinkText label='Read article' type='small' url={keyToUrl(article.key)} testID={`Read ${article.title}`} strong />
                </View>
            </HorizBox>
        </View>
    </View>
}

const ArticleCardThreadStyle = StyleSheet.create({
    outer: {
        backgroundColor: "#F7F7F7", 
        borderRadius: 8, 
        padding: 12 
    },
    articleList: {
        gap: 4, 
        flexShrink: 1 
    },
    image: {
        width: 48,
        height: 48,
        borderRadius: 8
    }
})

function ThreadDetailTabs({ thread, threadKey }) {
    const tabs = useMemo(() => [{
        id: "0",
        label: "Comments",
        component: () => (
            <View>
                <ModerationCommentOverview structureKey={"question"} instanceKey={threadKey} />
            </View>
        )
    }], [thread.key]);

    return (
        <View style={{flexGrow: 1 }}>
            <TabNavigation initialTabId={0} tabs={tabs}>
                <TabBar />
                <TabContent />
            </TabNavigation>
        </View>
    );
}